import React from "react";
import { Link, Button, Box, TextField, Typography } from "@mui/material";
//Assets
// Local
import {
  Form,
  Navigation,
  useFormik,
  useLocation,
  yup,
  RouterLink,
} from "../../lib";
import { authActions, useAction } from "../../state";
import { useStyles } from "./Auth.styles";
import AuthPages from ".";
import logo from "../../assets/logo/alpine-logo.png";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
});

export const ForgotPasswordPage = React.memo(
  /**
   *
   */
  function ForgotPasswordPage(props) {
    const classes = useStyles();
    const recoverPassword = useAction(authActions.recoverPassword);
    const location = useLocation();

    const frm = useFormik({
      initialValues: {
        email: location.query.email || "",
      },
      validationSchema,
      async onSubmit(values) {
        await recoverPassword(values);
        // @ts-ignore
        Navigation.go(AuthPages.recoverySent.path, {
          query: { email: values.email },
        });
      },
    });

    return (
      <Box className={classes.authCard}>
        <div className={classes.logo}>
          <img src={logo} alt="" height={72} />
        </div>
        <Typography variant="h5" className={classes.titleText}>
          Password Recovery
        </Typography>
        <Typography
          variant="body2"
          sx={{ mt: 1, mb: 1 }}
          className={classes.subtitleText}
        >
          Enter your email address and we will send you a link to reset your
          password
        </Typography>
        <Form form={frm} className={classes.form} sx={{ mt: 10, mb: 5 }}>
          <TextField
            sx={{ mt: 5, mb: 1 }}
            autoFocus
            fullWidth
            id="email"
            name="email"
            label="Email"
            value={frm.values.email}
            onChange={frm.handleChange}
            error={frm.touched.email && Boolean(frm.errors.email)}
            helperText={frm.touched.email && frm.errors.email}
            className={classes.textFieldInput}
            variant="standard"
          />
          <div className={classes.buttonBox}>
            <Button
              variant="contained"
              type="submit"
              disabled={frm.isSubmitting}
            >
              Reset Password
            </Button>
            <Link
              className={classes.forgotPassBox}
              component={RouterLink}
              to={`/auth/login`}
            >
              Back to sign in
            </Link>
          </div>
        </Form>
      </Box>
    );
  },
);
