export class FilterModeler {
  public static addFilter(newFilter: any, filterModel) {
    if (!filterModel || !filterModel.items || filterModel.items.length === 0) {
      return {
        items: [{ ...newFilter, id: 0 }],
      };
    }
    const newFilterModel = {
      items: [
        ...filterModel.items?.map((item, index) => {
          return { ...item, id: index };
        }),
        { ...newFilter, id: filterModel.items.length },
      ],
    };
    return newFilterModel;
  }
  public static removeFilter(columnField, filterModel) {
    const newFilterModel = {
      items: filterModel.items?.filter((item) => {
        return item.columnField !== columnField;
      }),
    };
    return newFilterModel;
  }
}
