import React, { useState } from "react";
import { Grid } from "@mui/material/";
import { dataSelectors, useSelector } from "../../../state";
import { FilterCard } from "../FilterCard";
import { GridLinkOperator } from "@mui/x-data-grid-pro";
import { FilterModeler } from "../../../lib/utils/FilterModeler";
interface Props {
  filterModel: any;
  setFilterModel: Function;
}
export const MiniDash = (props: Props) => {
  const [active, setActive] = useState("All Jobs");
  const jobStats = useSelector(dataSelectors.jobStats);
  return (
    <Grid sx={{ mb: 3 }} container justifyContent={"space-between"}>
      <FilterCard
        active={active}
        label={"All Jobs"}
        amount={jobStats.all.toLocaleString("en-US")}
        color={"#000000"}
        onClick={() => {
          props.setFilterModel({
            items: [],
          });

          setActive("All Jobs");
        }}
      />
      <FilterCard
        active={active}
        label={"In Process"}
        amount={jobStats.inProcess.toLocaleString("en-US")}
        color={"#17a2b8"}
        onClick={() => {
          let newFilterModel = FilterModeler.removeFilter(
            "status_id",
            props.filterModel,
          );
          props.setFilterModel({
            ...newFilterModel,
            items: [
              {
                columnField: "status_id",
                operatorValue: "equals",
                value: 2,
              },
            ],
          });
          setActive("In Process");
        }}
      />
      <FilterCard
        active={active}
        label={"Completed"}
        amount={jobStats.completed.toLocaleString("en-US")}
        color={"#4CAF50"}
        onClick={() => {
          let newFilterModel = FilterModeler.removeFilter(
            "status_id",
            props.filterModel,
          );
          props.setFilterModel({
            ...newFilterModel,
            items: [
              {
                columnField: "status_id",
                operatorValue: "equals",
                value: 3,
              },
            ],
          });
          setActive("Completed");
        }}
      />
      <FilterCard
        active={active}
        label={"Rejected & Missing Paperwork"}
        amount={jobStats.rejectedMissing.toLocaleString("en-US")}
        color={"#F44336"}
        onClick={() => {
          let newFilterModel = FilterModeler.removeFilter(
            "status_id",
            props.filterModel,
          );

          props.setFilterModel({
            ...newFilterModel,
            items: [
              {
                id: 1,
                columnField: "status_id",
                operatorValue: "equals",
                value: 1,
              },
              {
                id: 2,
                columnField: "status_id",
                operatorValue: "equals",
                value: 4,
              },
            ],
            linkOperator: GridLinkOperator.Or,
          });
          setActive("Rejected & Missing Paperwork");
        }}
      />
      <FilterCard
        active={active}
        label={"Paid"}
        amount={jobStats.paid.toLocaleString("en-US")}
        color={"#4CAF50"}
        onClick={() => {
          let newFilterModel = FilterModeler.removeFilter(
            "payment_status_id",
            props.filterModel,
          );
          props.setFilterModel({
            ...newFilterModel,
            items: [
              {
                id: 1,
                columnField: "payment_status_id",
                operatorValue: "equals",
                value: 4,
              },
            ],
          });
          setActive("Paid");
        }}
      />
      <FilterCard
        active={active}
        label={"Unpaid"}
        amount={jobStats.unpaid.toLocaleString("en-US")}
        color={"#FFC107"}
        onClick={() => {
          let newFilterModel = FilterModeler.removeFilter(
            "payment_status_id",
            props.filterModel,
          );
          props.setFilterModel({
            ...newFilterModel,
            items: [
              {
                id: 1,
                columnField: "payment_status_id",
                operatorValue: "equals",
                value: 2,
              },
              {
                id: 2,
                columnField: "payment_status_id",
                operatorValue: "equals",
                value: 3,
              },
            ],
            linkOperator: GridLinkOperator.Or,
          });
          setActive("Unpaid");
        }}
      />
    </Grid>
  );
};
