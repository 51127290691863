import { AppState, defaultJob, defaultJobStats } from "../types";
import { arrayToObjById } from "../utils";

export const dataSelectors = {
  statuses(state: AppState) {
    return state.data.statuses;
  },
  editCheckNumSuccess(state: AppState) {
    return state.data.editCheckNumSuccess;
  },
  paymentStatuses(state: AppState) {
    return state.data.paymentStatuses;
  },
  checkStatuses(state: AppState) {
    return state.data.checkStatuses;
  },
  makes(state: AppState) {
    return state.data.makes;
  },
  checks(state: AppState) {
    return state.data.checks;
  },
  credits(state: AppState) {
    return state.data.credits;
  },
  dealerships(state: AppState) {
    return state.data.dealerships;
  },
  activeDealership(state: AppState) {
    return state.data.activeDealership;
  },
  dealershipDetails(state: AppState) {
    return state.data.dealershipDetails;
  },
  jobs(state: AppState) {
    return state.data.jobs ?? [];
  },
  jobStats(state: AppState) {
    return state.data.jobStats ?? defaultJobStats;
  },
  jobsObj(state: AppState) {
    return arrayToObjById(state.data.jobs, "id");
  },
  activeJob(state: AppState) {
    return state.data.activeJob ?? defaultJob;
  },
  services(state: AppState) {
    return state.data.services;
  },

  users(state: AppState) {
    return state.data.users;
  },
  roles(state: AppState) {
    return state.data.roles;
  },
};
