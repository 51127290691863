import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { Toolbar, Paper, Typography, IconButton, Button } from "@mui/material";
import { uiSelectors, useSelector } from "../../state";
import { drawerWidth } from "./MiniDrawer";
import { Link, useOutlet } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AddIcon } from "../../components";
import { ActionBox } from "../../pages/admin/jobs/JobsPage.styles";
import { useMobile } from "../../themes";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `100%)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const PaperBar = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `100%`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function TopBar() {
  const menuOpen = useSelector(uiSelectors.menuOpen);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentDrawerWidth, setCurrentDrawerWidth] = useState(drawerWidth);
  const outlet = useOutlet();

  const childrenProps = outlet?.props.children.props ?? {};

  const pageTitle = childrenProps.title ?? childrenProps.children?.props.title;
  const backLink = childrenProps.back ?? childrenProps.children?.props.back;
  const formLink = childrenProps.form ?? childrenProps.children?.props.form;
  React.useEffect(() => {
    if (menuOpen) {
      setCurrentDrawerWidth(drawerWidth);
    } else {
      setCurrentDrawerWidth(73);
    }
  }, [menuOpen]);
  const isMobile = useMobile();
  return (
    <PaperBar elevation={0} position="sticky" sx={{ width: "100%" }}>
      <Toolbar disableGutters={true} sx={{ justifyContent: "space-between" }}>
        <Typography variant="h1" noWrap component="h1">
          {backLink && (
            <Link to={backLink}>
              <IconButton>
                <ArrowBackIcon />
              </IconButton>
            </Link>
          )}
          {pageTitle}
        </Typography>
        <ActionBox>
          {formLink && (
            <Link
              style={{ width: isMobile ? "100%" : undefined }}
              to={formLink}
            >
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disableElevation={true}
                sx={{ minWidth: 112 }}
              >
                <AddIcon sx={{ mr: 3 }} /> Add
              </Button>
            </Link>
          )}
        </ActionBox>
      </Toolbar>
    </PaperBar>
  );
}
