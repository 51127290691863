import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { states } from "../../assets/data/us_states";

export const StateAutocompleteFilter = (props: any) => {
  const [open, setOpen] = useState(false);

  return (
    <Autocomplete
      sx={props.sx}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option?.abbr === value?.abbr}
      getOptionLabel={(option: any) => {
        return `${option?.abbr} - ${option?.name}` || "";
      }}
      onChange={(event, value) => {
        if (props.onChange) {
          props.onChange(event, value?.abbr);
        }
      }}
      options={states}
      renderInput={(params) => (
        <TextField
          {...params}
          label="State"
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  );
};
