import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useFormikContext } from "formik";
import { states } from "../../assets/data/us_states";

export default function StateAutocomplete(props: any) {
  const [open, setOpen] = useState(false);

  const { setFieldValue, touched, errors, initialValues }: any =
    useFormikContext();

  const [value, setValue]: any = useState(initialValues?.state);

  useEffect(() => {
    const _value = states.find((s) => s.abbr === initialValues?.state);
    if (_value) {
      setValue(_value);
    } else {
      setValue("");
    }
  }, [initialValues?.state]);

  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(event, value) => {
        setFieldValue("state", value?.abbr);
        setValue(value);
      }}
      loading={false}
      options={states}
      value={value}
      isOptionEqualToValue={(option, value) => option?.abbr === value?.abbr}
      getOptionLabel={(option: any) =>
        `${
          typeof option === "object" ? option.abbr + "- " + option.name : option
        }`
      }
      renderInput={(params) => (
        <TextField
          required={true}
          error={touched.state && Boolean(errors.state)}
          helperText={touched.state && errors.state}
          {...params}
          label="State"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
