import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import {
  listsActions,
  listsSelectors,
  useDispatch,
  useSelector,
} from "../../state";

export const DealershipAutocompleteFilter = (props: any) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const dealershipList: any = useSelector(listsSelectors.dealershipsList);

  const loading = open && dealershipList.length === 0;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [value, setValue]: any = useState("");

  useEffect(() => {
    dispatch(listsActions.getDealershipList());
  }, [dispatch]);

  return (
    <Autocomplete
      sx={props.sx}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      getOptionLabel={(option: any) => option?.dealership_name || ""}
      onChange={(event, value) => {
        setValue(value);
        if (props.onChange) {
          props.onChange(event, value?.id);
        }
      }}
      options={dealershipList}
      loading={loading}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label="Dealership"
            InputProps={{
              ...params.InputProps,

              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        );
      }}
    />
  );
};
