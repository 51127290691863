import UserPage from "./UserPage";
import { UsersPage } from "./UsersPage";
import { MakesPage, MakePage } from "./makes/";
import ProfilePage from "./ProfilePage";
import { DealershipPage, DealershipsPage } from "./dealerships";
import JobPage from "./jobs/JobPage";
import { JobsPage } from "./jobs";
import DealershipFormPage from "./dealerships/DealershipFormPage";
import { ChecksPage } from "./checks";

export const _AdminPages = {
  users: {
    anon: false,
    path: "/admin/users",
    title: "Users",
    view: UsersPage,
    form: "/admin/users/create",
  },
  createUsers: {
    anon: false,
    path: "/admin/users/create",
    title: "Create User",
    view: UserPage,
    back: "/admin/users",
  },
  editUsers: {
    anon: false,
    path: "/admin/users/:id",
    title: "Edit User",
    view: UserPage,
    back: "/admin/users",
  },
  profile: {
    anon: false,
    path: "/admin/profile",
    title: "Edit Profile",
    view: ProfilePage,
  },

  createMakes: {
    anon: false,
    path: "/admin/makes/create",
    title: "Create Make",
    view: MakePage,
    back: "/admin/makes",
  },
  editMakes: {
    anon: false,
    path: "/admin/makes/:id",
    title: "Edit Make",
    view: MakePage,
    back: "/admin/makes",
  },
  makes: {
    anon: false,
    path: "/admin/makes",
    title: "Makes",
    view: MakesPage,
    form: "/admin/makes/create",
  },
  createDealership: {
    anon: false,
    path: "/admin/dealerships/create",
    title: "Create Dealership",
    view: DealershipFormPage,
    back: "/admin/dealerships",
  },
  viewDealership: {
    anon: false,
    path: "/admin/dealerships/:id/view",
    title: "",
    view: DealershipPage,
  },
  editDealership: {
    anon: false,
    path: "/admin/dealerships/:id",
    title: "Edit Dealership",
    view: DealershipFormPage,
    back: "/admin/dealerships",
  },
  dealerships: {
    anon: false,
    path: "/admin/dealerships",
    title: "Dealerships",
    view: DealershipsPage,
    form: "/admin/dealerships/create",
  },
  createJob: {
    anon: false,
    path: "/admin/jobs/create",
    title: "Create Job",
    view: JobPage,
    back: "/admin/jobs",
  },
  editJob: {
    anon: false,
    path: "/admin/jobs/:id",
    title: "Edit Job",
    view: JobPage,
    back: "/admin/jobs",
  },
  jobs: {
    anon: false,
    path: "/admin/jobs",
    title: "Jobs",
    view: JobsPage,
    form: "/admin/jobs/create",
  },
  checks: {
    anon: false,
    path: "/admin/checks",
    title: "Checks",
    view: ChecksPage,
    // form: "/admin/jobs/create",
  },
};

export const AdminPages = Object.freeze(_AdminPages);
export default AdminPages;
