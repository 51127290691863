import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../types";

interface Users {
  count: number;
  rows: User[];
}

export interface AdminState {
  users: Users;
}

const initialState: AdminState = {
  users: {
    count: 0,
    rows: [],
  },
};

export const admin = {
  ...createSlice({
    name: "admin",
    initialState,
    reducers: {
      setUsers(state, { payload }: PayloadAction<Users>) {
        state.users = payload;
      },
    },
  }),
  persist: false,
};
