import React, { useEffect, useState } from "react";
import { Grid, Button, Card, CardContent } from "@mui/material";
import { dataSelectors, useDispatch, useSelector } from "../../../state";
import { TextField } from "formik-mui";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { dataActions } from "../../../state";
import { useParams } from "react-router";
import { RootStyled, GridStyled } from "./DealershipFormPage.styles";
import ActiveInactiveToggle from "../../../components/inputs/ActiveInactiveToggle";

const validationSchema = yup.object({
  dealership_name: yup.string().required("Name is required"),
});

const DealershipFormPage = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [editMode, setEditMode] = useState(false);
  const activeDealership = useSelector(dataSelectors.activeDealership);
  const [initialValues, setInitialValues] = useState({
    dealership_name: "",
    contact_name: "",
    email_address: "",
    address: "",
    address_2: "",
    city: "",
    state: "",
    zipcode: "",
    phone_1: "",
    phone_2: "",
    fax: "",
    is_active: true,
  });
  useEffect(() => {
    if (params?.id) {
      dispatch(dataActions.getDealership(params.id));
    }
  }, [params?.id, dispatch]);

  useEffect(() => {
    if (activeDealership && params?.id) {
      setInitialValues(activeDealership);
      setEditMode(true);
    }
  }, [activeDealership, params?.id]);

  const handleSubmit = (values, { setSubmitting }) => {
    if (editMode) {
      dispatch(dataActions.updateDealership(params.id, values));
    } else {
      dispatch(dataActions.createDealership(values));
    }
    setSubmitting(false);
  };

  return (
    <RootStyled>
      <Card>
        <CardContent sx={{ pt: 6 }}>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, values }) => (
              <Form>
                <Grid container>
                  <GridStyled item>
                    <Field
                      component={TextField}
                      fullWidth
                      name="dealership_name"
                      label="Dealership Name"
                      id="dealership_name"
                      required
                      error={
                        touched.dealership_name &&
                        Boolean(errors.dealership_name)
                      }
                      helperText={
                        touched.dealership_name && errors.dealership_name
                      }
                    />
                  </GridStyled>
                  <GridStyled item>
                    <Field
                      component={TextField}
                      fullWidth
                      name="contact_name"
                      label="Contact Name"
                      error={
                        touched.contact_name && Boolean(errors.contact_name)
                      }
                      helperText={touched.contact_name && errors.contact_name}
                    />
                  </GridStyled>
                </Grid>
                <Grid container>
                  <GridStyled item>
                    <Field
                      component={TextField}
                      fullWidth
                      name="email_address"
                      label="Email Address"
                      error={
                        touched.email_address && Boolean(errors.email_address)
                      }
                      helperText={touched.email_address && errors.email_address}
                    />
                  </GridStyled>
                </Grid>
                <Grid container>
                  <GridStyled item>
                    <Field
                      component={TextField}
                      fullWidth
                      name="address"
                      label="Address Line 1"
                      error={touched.address && Boolean(errors.address)}
                      helperText={touched.address && errors.address}
                    />
                  </GridStyled>
                  <GridStyled item>
                    <Field
                      component={TextField}
                      fullWidth
                      name="address_2"
                      label="Address Line 2"
                      error={touched.address_2 && Boolean(errors.address_2)}
                      helperText={touched.address_2 && errors.address_2}
                    />
                  </GridStyled>
                </Grid>
                <Grid container>
                  <GridStyled item>
                    <Field
                      component={TextField}
                      fullWidth
                      name="city"
                      label="City"
                      error={touched.city && Boolean(errors.city)}
                      helperText={touched.city && errors.city}
                    />
                  </GridStyled>
                  <GridStyled item>
                    <Field
                      component={TextField}
                      fullWidth
                      name="state"
                      label="State"
                      error={touched.state && Boolean(errors.state)}
                      helperText={touched.state && errors.state}
                    />
                  </GridStyled>
                </Grid>
                <Grid container>
                  <GridStyled item>
                    <Field
                      component={TextField}
                      fullWidth
                      name="zipcode"
                      label="Zipcode"
                      error={touched.zipcode && Boolean(errors.zipcode)}
                      helperText={touched.zipcode && errors.zipcode}
                    />
                  </GridStyled>
                  <GridStyled item>
                    <Field
                      component={TextField}
                      fullWidth
                      name="phone_1"
                      label="Phone"
                      error={touched.phone_1 && Boolean(errors.phone_1)}
                      helperText={touched.phone_1 && errors.phone_1}
                    />
                  </GridStyled>
                </Grid>
                <Grid container>
                  <GridStyled item>
                    <Field
                      component={TextField}
                      fullWidth
                      name="phone_2"
                      label="Phone 2"
                      error={touched.phone_2 && Boolean(errors.phone_2)}
                      helperText={touched.phone_2 && errors.phone_2}
                    />
                  </GridStyled>
                  <GridStyled item>
                    <Field
                      component={TextField}
                      fullWidth
                      name="fax"
                      label="Fax"
                      error={touched.fax && Boolean(errors.fax)}
                      helperText={touched.fax && errors.fax}
                    />
                  </GridStyled>
                </Grid>
                <Grid container>
                  <GridStyled item>
                    <Field
                      component={ActiveInactiveToggle}
                      fullWidth
                      name="is_active"
                      label="Active"
                      value={values?.is_active}
                      error={touched.is_active && Boolean(errors.is_active)}
                      helperText={touched.is_active && errors.is_active}
                    />
                  </GridStyled>
                </Grid>
                <Grid container justifyContent="flex-end">
                  <GridStyled
                    item
                    sx={{ display: "flex", justifyContent: "end" }}
                  >
                    <Button
                      id="submit"
                      color="primary"
                      variant="contained"
                      type="submit"
                      // disabled={isSubmitting}
                    >
                      Save
                    </Button>
                  </GridStyled>
                </Grid>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </RootStyled>
  );
};
export default DealershipFormPage;
