import { AppThunk } from "..";
import { Navigation } from "../../lib";
import AdminPages from "../../pages/admin";
import { authClient, uiActions } from "../states";
import { admin } from "./state";

const { actions } = admin;

export const adminActions = {
  ...actions,
  getUsers(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/users`);
      if (status === 200) {
        dispatch(uiActions.showSuccess("Get users succeeded"));
        dispatch(actions.setUsers(data));
      } else {
        dispatch(uiActions.showError("Get users failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getUser(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/users/` + id);
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(uiActions.showError("failed to get user"));
        return false;
      } else {
        return data;
      }
    };
  },
  createUser(values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.post(`/users`, values);
      if (status === 204) {
        dispatch(uiActions.showSuccess("user saved"));
        dispatch(adminActions.getUsers());
        Navigation.go(AdminPages.users.path);
      } else {
        dispatch(uiActions.showError("failed to save user"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  updateUser(id, values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.put(`/users/${id}`, values);
      if (status === 204) {
        dispatch(uiActions.showSuccess("user updated"));
        dispatch(adminActions.getUsers());
        Navigation.go(AdminPages.users.path);
      } else {
        dispatch(uiActions.showError("failed to save user"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  updateProfile(values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.put(`/profile`, values);
      if (status === 204) {
        dispatch(uiActions.showSuccess("Profile updated"));
        Navigation.go(AdminPages.profile.path);
      } else {
        dispatch(uiActions.showError("Profile update failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
};
