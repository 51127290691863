import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Typography,
  FormGroup,
  FormControlLabel,
  IconButton,
  Tooltip,
  TextField as MUITextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { TextField, Checkbox } from "formik-mui";
import { Form, Field, useFormikContext } from "formik";
import AdminPages from "../index";
import { SectionHeader } from "../SectionHeader";
import { ConfirmDeleteDialog } from "../../../components/dialogs/ConfirmDeleteDialog";
import { AddIcon } from "../../../components";
import {
  GridStyled,
  GridContainer,
  ErrorMessageStyled,
} from "./JobPage.styles";
import {
  dataSelectors,
  objToArray,
  useDispatch,
  useSelector,
  dataActions,
  PaymentStatusType,
} from "../../../state";
import {
  FormikDollarField,
  NumberFormatter,
  DealershipAutocomplete,
  NameIdAutocomplete,
  StateAutocomplete,
} from "../../../components/inputs";
import { Edit } from "@mui/icons-material";

const currency = require("currency.js");

const JobForm = (props: any) => {
  const { editMode, params } = props;
  const dispatch = useDispatch();
  const { values, setFieldValue, touched, errors }: any = useFormikContext();
  const statusSlice = objToArray(useSelector(dataSelectors.statuses));
  const makesSlice = objToArray(useSelector(dataSelectors.makes));
  const servicesSlice = objToArray(useSelector(dataSelectors.services));
  const paymentStatusesSlice = objToArray(
    useSelector(dataSelectors.paymentStatuses),
  );
  const editCheckNumSuccess = useSelector(dataSelectors.editCheckNumSuccess);
  const [jobTotal, setJobTotal] = useState(0);
  const [newCheckTotal, setNewCheckTotal] = useState(0);
  const [totalChecksAmt, setTotalChecksAmt] = useState(0);
  const [editCheckNumMode, setEditCheckNumMode]: any = useState();

  useEffect(() => {
    setJobTotal(
      currency(values?.mv_fee)
        .add(values?.alpine_fee)
        .add(values?.sales_tax)
        .format(),
    );
  }, [values?.mv_fee, values?.alpine_fee, values?.sales_tax]);

  useEffect(() => {
    if (!!values?.checks?.length) {
      const totalChecksAmt = values.checks
        ?.map((check: any) => check.check_jobs)
        .reduce((a: any, b: any) => {
          return a + Number(b ? b.amount : 0);
        }, 0);
      setTotalChecksAmt(totalChecksAmt);
    } else {
      setTotalChecksAmt(0);
    }
  }, [values?.checks]);

  useEffect(() => {
    setNewCheckTotal(currency(jobTotal).subtract(totalChecksAmt).value);
  }, [totalChecksAmt, jobTotal]);

  const handleEditCheckNumber = (check) => {
    setEditCheckNumMode({ check_number: check.check_number, id: check.id });
  };
  const handleSaveEditCheckNumber = (check) => {
    dispatch(dataActions.editCheckNumber(check.id, check.check_number));
  };
  const handleCancel = (i) => {
    setFieldValue(`checks[${i}].check_number`, editCheckNumMode?.check_number);
    setEditCheckNumMode(undefined);
  };

  useEffect(() => {
    if (editCheckNumSuccess) {
      setEditCheckNumMode(undefined);
    }
  }, [editCheckNumSuccess]);

  return (
    <Form>
      <Grid container>
        <GridStyled item>
          <StateAutocomplete
            editMode={editMode}
            fullWidth
            name="state"
            label="State"
            id="state"
          />
        </GridStyled>
        <GridStyled item>
          <Field
            component={DealershipAutocomplete}
            fullWidth
            name="dealership_id"
            label="Dealership"
            id="dealership_name"
            required
            disabled={editMode}
            error={touched.dealership_id && Boolean(errors.dealership_id)}
            helperText={touched.dealership_id && errors.dealership_id}
          />
        </GridStyled>
      </Grid>
      <Grid container>
        <GridStyled item>
          <NameIdAutocomplete
            required
            options={statusSlice}
            label="Job Status"
            getOptionsAction={dataActions.getStatuses}
            fullWidth={true}
            name="status_id"
          />
        </GridStyled>
        <GridStyled item>
          <NameIdAutocomplete
            disabled={
              !!values?.payment_status_id &&
              (values?.payment_status_id === PaymentStatusType.PARTIALLY_PAID ||
                values?.payment_status_id === PaymentStatusType.PAID)
            }
            required
            getOptionDisabled={(option) => {
              return option === 3 || option === 4;
            }}
            options={paymentStatusesSlice}
            label="Payment Status"
            initialValue={1}
            getOptionsAction={dataActions.getPaymentStatuses}
            fullWidth={true}
            name="payment_status_id"
          />
        </GridStyled>
      </Grid>
      <Grid
        sx={{ marginLeft: "32px", paddingRight: "220px" }}
        container={true}
        justifyContent={"space-between"}
      >
        <SectionHeader variant={"h4"} label={"Job details"} />
      </Grid>

      <Grid container>
        <GridStyled item>
          <Field
            component={TextField}
            fullWidth
            name="vehicle_year"
            label="Year"
            value={values?.vehicle_year}
            InputProps={{
              inputComponent: NumberFormatter,
            }}
            inputProps={{ maxLength: 4 }}
          />
        </GridStyled>
        <GridStyled item>
          <NameIdAutocomplete
            options={makesSlice}
            getOptionsAction={dataActions.getMakes}
            fullWidth
            name="make_id"
            label="Make"
          />
        </GridStyled>
      </Grid>

      <Grid container>
        <GridStyled item>
          <Field
            component={TextField}
            fullWidth
            name="miscellaneous"
            label="Misc"
            error={touched.miscellaneous && Boolean(errors.miscellaneous)}
            helperText={touched.miscellaneous && errors.miscellaneous}
          />
        </GridStyled>
        <GridStyled item>
          <Field
            component={TextField}
            fullWidth
            name="customer_name"
            label="Customer Name"
            error={touched.customer_name && Boolean(errors.customer_name)}
            helperText={touched.customer_name && errors.customer_name}
          />
        </GridStyled>
      </Grid>

      {values && values.state && values.state === "NY" && (
        <Grid container>
          <GridStyled item>
            <Field
              component={TextField}
              fullWidth
              name="customer_address"
              label="Customer Address"
              error={
                touched.customer_address && Boolean(errors.customer_address)
              }
              helperText={touched.customer_address && errors.customer_address}
            />
          </GridStyled>
        </Grid>
      )}
      <Grid
        sx={{ marginLeft: "32px", paddingRight: "220px" }}
        container={true}
        justifyContent={"space-between"}
      >
        <SectionHeader variant={"h4"} label={"Billing"} />

        <Typography variant="body1" gutterBottom>
          Total:
          {jobTotal}
        </Typography>
      </Grid>

      <Grid container>
        <GridStyled item>
          <NameIdAutocomplete
            required
            fullWidth
            name="service_id"
            label="Service"
            options={servicesSlice}
            getOptionsAction={dataActions.getServices}
          />
        </GridStyled>
        <GridStyled item>
          <DatePicker
            label="Invoice Date"
            value={values.invoice_date}
            onChange={(newValue) => {
              setFieldValue("invoice_date", newValue);
            }}
            renderInput={(params) => <MUITextField {...params} />}
          />
          <ErrorMessageStyled
            name="invoice_date"
            component="div"
            sx={{ color: "red" }}
          />
        </GridStyled>
      </Grid>
      <Grid container>
        <GridStyled item>
          <FormikDollarField fieldName="alpine_fee" label="Alpine Fee" />
        </GridStyled>
        <GridStyled item>
          <FormikDollarField fieldName="mv_fee" label="MV Fee" />
        </GridStyled>
      </Grid>
      <Grid>
        <GridStyled item>
          <FormikDollarField fieldName="sales_tax" label="Sales Tax" />
        </GridStyled>
        <GridStyled item></GridStyled>
      </Grid>
      {values && values.checks && values.checks.length > 0 && (
        <Grid
          sx={{ marginLeft: "32px", paddingRight: "220px" }}
          container={true}
          justifyContent={"space-between"}
        >
          <SectionHeader variant={"h4"} label={"Payment"} />
        </Grid>
      )}
      {values &&
        values.checks &&
        values.checks.map((check, i) => (
          <GridContainer container key={i}>
            <Grid item>
              <Field
                required
                disabled={
                  editMode && check.id && check.id !== editCheckNumMode?.id
                }
                component={TextField}
                fullWidth
                name={`checks[${i}].check_number`}
                label="Check Number"
                error={
                  !!touched.checks &&
                  touched.checks[i] &&
                  !!touched.checks[i].check_number &&
                  Boolean(
                    errors.checks &&
                      errors.checks[i] &&
                      errors.checks[i]["check_number"],
                  )
                }
              />
            </Grid>
            <Grid item>
              <DatePicker
                label="Check Date"
                disabled={
                  check.id &&
                  !!check.check_jobs?.amount &&
                  check.check_status?.name !== "BLANK"
                }
                value={values.checks[i].check_date}
                onChange={(newValue) => {
                  setFieldValue(`checks[${i}].check_date`, newValue);
                }}
                renderInput={(params) => <MUITextField {...params} required />}
              />
            </Grid>
            <Grid item>
              <Field
                disabled={
                  check.id &&
                  !!check.check_jobs?.amount &&
                  check.check_status?.name !== "BLANK"
                }
                component={TextField}
                fullWidth
                name={`checks[${i}].check_jobs.amount`}
                label="Amount"
                error={
                  !!touched.checks &&
                  touched.checks[i] &&
                  !!touched.checks[i].amount &&
                  Boolean(
                    errors.checks &&
                      errors.checks[i] &&
                      errors.checks[i]["amount"],
                  )
                }
              />
            </Grid>
            <Grid item>
              {!!check.id && !editCheckNumMode && (
                <IconButton
                  onClick={() => {
                    handleEditCheckNumber(check);
                  }}
                  color="default"
                  size="small"
                >
                  <Edit />
                </IconButton>
              )}
              {editCheckNumMode && check.id === editCheckNumMode?.id && (
                <Button
                  onClick={() => {
                    handleSaveEditCheckNumber(check);
                  }}
                  color="success"
                  size="small"
                >
                  Save Changes
                </Button>
              )}
              {editCheckNumMode && check.id === editCheckNumMode?.id && (
                <Button
                  onClick={() => {
                    handleCancel(i);
                  }}
                  color="error"
                  size="small"
                >
                  cancel
                </Button>
              )}
              {((check.check_status?.name === "BLANK" && !editCheckNumMode) ||
                !check.id) && (
                <ConfirmDeleteDialog
                  handleConfirm={() => {
                    if (check.id) {
                      dispatch(
                        dataActions.removeCheckFromJob(check.id, params.id),
                      );
                    } else {
                      setFieldValue(
                        "checks",
                        values.checks.filter((check, j) => j !== i),
                      );
                    }
                  }}
                />
              )}
            </Grid>
          </GridContainer>
        ))}
      <GridContainer>
        {totalChecksAmt > currency(jobTotal).value && (
          <SectionHeader
            variant={"h4"}
            label={`Refund due: $${totalChecksAmt - currency(jobTotal).value}`}
          />
        )}
        {totalChecksAmt < currency(jobTotal).value && (
          <SectionHeader
            variant={"h4"}
            label={`Balance due: $${currency(jobTotal).value - totalChecksAmt}`}
          />
        )}
      </GridContainer>
      <Grid container>
        <GridStyled>
          <Button
            disableElevation={true}
            variant={"contained"}
            disabled={totalChecksAmt >= currency(jobTotal).value}
            color="primary"
            onClick={() => {
              setFieldValue("checks", [
                ...values.checks,
                {
                  id: NaN,
                  check_number: null,
                  amount: null,
                  check_jobs: { amount: newCheckTotal },
                  dealership_id: null,
                  check_status_id: null,
                  check_date: new Date(),
                },
              ]);
            }}
            startIcon={<AddIcon />}
          >
            Add Check
          </Button>
        </GridStyled>
      </Grid>
      <Grid
        sx={{ marginLeft: "32px", paddingRight: "220px" }}
        container={true}
        justifyContent={"space-between"}
      >
        <SectionHeader variant={"h4"} label={"Paperwork"} />
      </Grid>
      <Grid container>
        <GridStyled item>
          <DatePicker
            label="Paperwork Received Date"
            value={values.paperwork_received_date}
            onChange={(newValue) => {
              setFieldValue("paperwork_received_date", newValue);
            }}
            renderInput={(params) => <MUITextField {...params} />}
          />
        </GridStyled>
        <GridStyled item>
          <DatePicker
            label="Paperwork Sent to MV"
            value={values.paperwork_sent_to_dmv}
            onChange={(newValue) => {
              setFieldValue("paperwork_sent_to_dmv", newValue);
            }}
            renderInput={(params) => <MUITextField {...params} />}
          />
        </GridStyled>
      </Grid>
      <Grid container>
        <GridStyled item>
          <DatePicker
            label="Estimated Return Date"
            value={values.paperwork_estimated_return}
            onChange={(newValue) => {
              setFieldValue("paperwork_estimated_return", newValue);
            }}
            renderInput={(params) => <MUITextField {...params} />}
          />
        </GridStyled>
        <GridStyled item>
          <DatePicker
            label="Paperwork Returned from MV"
            value={values.paperwork_returned_from_dmv}
            onChange={(newValue) => {
              setFieldValue("paperwork_returned_from_dmv", newValue);
            }}
            renderInput={(params) => <MUITextField {...params} />}
          />
        </GridStyled>
      </Grid>
      <Grid>
        <GridStyled item>
          <Field
            component={TextField}
            fullWidth
            name={`paperwork_tracking`}
            label="Tracking"
            error={
              !!touched.paperwork_tracking && Boolean(errors.paperwork_tracking)
            }
            helperText={touched.paperwork_tracking && errors.paperwork_tracking}
          />
        </GridStyled>
      </Grid>
      <Grid
        sx={{ marginLeft: "32px", paddingRight: "220px" }}
        container={true}
        justifyContent={"space-between"}
      >
        <FormGroup>
          <FormControlLabel
            name="rejected"
            control={
              <Field
                component={Checkbox}
                type="checkbox"
                name="rejected"
                checked={values?.rejected}
              />
            }
            label="Received Rejection"
          />
        </FormGroup>
      </Grid>
      <Grid container>
        <GridStyled item>
          <Field
            component={TextField}
            name={"rejection_reason"}
            label="Rejection Reason"
            error={
              !!touched.rejection_reason && Boolean(errors.rejection_reason)
            }
            helperText={touched.rejection_reason && errors.rejection_reason}
            fullWidth
          />
        </GridStyled>{" "}
        <GridStyled item>
          <DatePicker
            label="Re-Sent to MV"
            value={values.rejection_sent_to_dmv_date}
            onChange={(newValue) => {
              setFieldValue("rejection_sent_to_dmv_date", newValue);
            }}
            renderInput={(params) => <MUITextField {...params} />}
          />
        </GridStyled>
      </Grid>
      <Grid container>
        <GridStyled item>
          <DatePicker
            label="Estimated Return Date"
            value={values.rejection_estimated_return}
            onChange={(newValue) => {
              setFieldValue("rejection_estimated_return", newValue);
            }}
            renderInput={(params) => <MUITextField {...params} />}
          />
        </GridStyled>
        <GridStyled item>
          <DatePicker
            label="Returned from MV"
            value={values.rejection_returned_from_dmv}
            onChange={(newValue) => {
              setFieldValue("rejection_returned_from_dmv", newValue);
            }}
            renderInput={(params) => <MUITextField {...params} />}
          />
        </GridStyled>
      </Grid>
      <Grid>
        <GridStyled item>
          <Field
            component={TextField}
            fullWidth
            name={`rejection_tracking`}
            label="Tracking"
            error={
              !!touched.rejection_tracking && Boolean(errors.rejection_tracking)
            }
            helperText={touched.rejection_tracking && errors.rejection_tracking}
          />
        </GridStyled>
      </Grid>
      <GridStyled sx={{ width: "100%" }} container>
        <Grid
          item
          xs={12}
          md={8}
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            padding: 0,
            "& > span": {
              width: "400px",
            },
            "& > Button": {
              marginRight: "24px",
              width: "400px",
            },
            "& > a": {
              marginRight: "24px",
              backgroundColor: "#E5E7F5",
              padding: "0 32px",
              color: "#000",
              width: "400px",
            },
          }}
        >
          <Button
            href={AdminPages.jobs.path}
            color="info"
            variant="contained"
            type="button"
            // disabled={isSubmitting}
            disableElevation={true}
          >
            Cancel
          </Button>

          <Tooltip
            title={
              !!editCheckNumMode
                ? "Save or cancel the edited check to submit this form."
                : ""
            }
          >
            <span>
              <Button
                sx={{
                  wordBreak: "break-all",
                  whiteSpace: "nowrap",
                }}
                color="primary"
                variant="contained"
                type="button"
                disabled={!!editCheckNumMode}
                onClick={() => {
                  console.log("Button Clicked");
                  const temp1 = Date.now();
                  console.log(temp1);
                  if (editMode) {
                    if (
                      values?.payment_status_id ===
                      PaymentStatusType.NOT_STARTED
                    ) {
                      dispatch(
                        dataActions.updateJob(
                          params.id,
                          { ...values, payment_status_id: 2 },
                          true,
                        ),
                      );
                    } else {
                      dispatch(dataActions.updateJob(params.id, values, true));
                    }
                  } else {
                    if (
                      values?.payment_status_id ===
                      PaymentStatusType.NOT_STARTED
                    ) {
                      dispatch(
                        dataActions.createJob(
                          {
                            ...values,
                            payment_status_id: PaymentStatusType.INVOICED,
                          },
                          true,
                        ),
                      );
                    } else dispatch(dataActions.createJob(values, true));
                  }
                }}
                // disabled={isSubmitting}
                disableElevation={true}
              >
                Save & Download
              </Button>
            </span>
          </Tooltip>
          <Tooltip
            title={
              !!editCheckNumMode
                ? "Save or cancel the edited check to submit this form."
                : ""
            }
          >
            <span>
              <Button
                disableElevation={true}
                color="primary"
                variant="contained"
                type="submit"
                disabled={!!editCheckNumMode}
                id="submit"
                sx={{ width: "100%", ml: 2 }}
                // disabled={isSubmitting}
              >
                Save
              </Button>
            </span>
          </Tooltip>
        </Grid>
      </GridStyled>
    </Form>
  );
};
export default JobForm;
