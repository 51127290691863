import React, { useEffect, useState } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import { RootStyled } from "./ChecksPage.styles";
import { dataActions } from "../../../state/data/actions";
import { dataSelectors } from "../../../state/data/selectors";
import { objToArray } from "../../../state";
import PrintIcon from "@mui/icons-material/Print";
import FilterRow from "./FilterRow";

export const ChecksPage = () => {
  const dispatch = useDispatch();
  const [filterModel, setFilterModel] = useState({ items: [] });
  const checks = useSelector(dataSelectors.checks);

  useEffect(() => {
    if (filterModel.items.length) {
      let query = "";
      filterModel.items.forEach((i: any) => {
        if (!query) {
          query += "?";
        } else {
          query += "&";
        }
        query += `${i.columnField}=${i.value}`;
      });
      dispatch(dataActions.getChecks(query));
    } else {
      dispatch(dataActions.getChecks());
    }
  }, [dispatch, filterModel]);

  const columns = [
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <IconButton
          color="primary"
          size="small"
          onClick={() => dispatch(dataActions.printReceiptOfCheck(params.id))}
        >
          <PrintIcon />
        </IconButton>
      ),
    },
    { field: "id", headerName: "Id", width: 150 },
    {
      field: "dealership_id",
      headerName: "Dealership",
      width: 150,
      valueGetter: (params) => {
        return params.row.dealership.dealership_name;
      },
    },
    { field: "check_number", headerName: "Check Number", width: 150 },
    { field: "check_date", headerName: "Check Date", width: 150 },
    { field: "amount", headerName: "Amount", width: 150 },
  ];

  return (
    <RootStyled>
      <FilterRow setFilterModel={setFilterModel} filterModel={filterModel} />

      <DataGridPro
        disableChildrenFiltering={true}
        disableColumnFilter={true}
        checkboxSelection={false}
        rows={objToArray(checks)}
        columns={columns}
      />
    </RootStyled>
  );
};
