import { auth, AuthState } from "./auth/state";
import { ui, UIState } from "./ui/state";
import { admin, AdminState } from "./admin/state";
import { lists, ListState } from "./lists/state";
import { data, DataState } from "./data/state";
import { AppStateInfo } from "./types";

// Export Actions, Selectors, Types

export * from "./auth/actions";
export * from "./auth/selectors";

export * from "./ui/actions";
export * from "./ui/selectors";

export * from "./admin/actions";
export * from "./admin/selectors";

export * from "./data/actions";
export * from "./data/selectors";

export * from "./lists/actions";
export * from "./lists/selectors";

export interface AppState {
  auth: AuthState;
  ui: UIState;
  admin: AdminState;
  data: DataState;
  lists: ListState;
}

const states: AppStateInfo[] = [
  // Export States
  auth,
  ui,
  admin,
  data,
  lists,
];
export default states;
