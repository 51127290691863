import { AppThunk } from "..";
import { Navigation } from "../../lib";
import { authClient, uiActions } from "../states";
import { data } from "./state";
import AdminPages from "../../pages/admin";
import { REACT_APP_API_URL } from "../../config";

const { actions } = data;

export const dataActions = {
  ...actions,
  getStatuses(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/statuses`);
      if (status === 200) {
        dispatch(actions.setStatuses(data));
      } else {
        dispatch(uiActions.showError("Get statuses failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },

  getStatus(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/status/${id}`);
      if (status === 200) {
        dispatch(actions.setStatus(data));
      } else {
        dispatch(uiActions.showError("Get status failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  createStatus(values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.post(`/status`, values);
      if (status === 204 || status === 200) {
        dispatch(uiActions.showSuccess("Status saved"));
        dispatch(actions.setStatus(data)); //does this work?
        Navigation.go(AdminPages.users.path);
      } else {
        dispatch(uiActions.showError("failed to save status"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  updateStatus(id, values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.put(`/status/${id}`, values);
      if (status === 204 || status === 200) {
        dispatch(uiActions.showSuccess("status updated"));
        dispatch(dataActions.setStatus(data));
        Navigation.go(AdminPages.users.path);
      } else {
        dispatch(uiActions.showError("failed to save status"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  deleteStatus(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.delete(`/status/${id}`);
      if (status === 204 || status === 200) {
        dispatch(uiActions.showSuccess("Status deleted"));
        // Navigation.go(AdminPages.status.path);
      } else {
        dispatch(uiActions.showError("Delete status failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getPaymentStatuses(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/payment_statuses`);
      if (status === 200) {
        dispatch(actions.setPaymentStatuses(data));
      } else {
        dispatch(uiActions.showError("Get payment statuses failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getPaymentStatusesFiltered(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/payment_statuses`);
      if (status === 200) {
        dispatch(actions.setPaymentStatusesFiltered(data));
      } else {
        dispatch(uiActions.showError("Get payment statuses failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getPaymentStatus(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/payment-statuses/${id}`);
      if (status === 200) {
        dispatch(actions.setPaymentStatus(data));
      } else {
        dispatch(uiActions.showError("Get payment status failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  createPaymentStatus(values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.post(
        `/payment-statuses`,
        values,
      );
      if (status === 204 || status === 200) {
        dispatch(uiActions.showSuccess("Payment status saved"));
        dispatch(actions.setPaymentStatus(data));
        Navigation.go(AdminPages.users.path);
      } else {
        dispatch(uiActions.showError("failed to save payment status"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  updatePaymentStatus(id, values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.put(
        `/payment-statuses/${id}`,
        values,
      );
      if (status === 204 || status === 200) {
        dispatch(uiActions.showSuccess("Payment status updated"));
        dispatch(dataActions.setPaymentStatus(data));
        Navigation.go(AdminPages.users.path);
      } else {
        dispatch(uiActions.showError("failed to save payment status"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  deletePaymentStatus(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.delete(`/payment-statuses/${id}`);
      if (status === 204 || status === 200) {
        dispatch(uiActions.showSuccess("Payment status deleted"));
        //Navigation.go(AdminPages.dashboard.path);
      } else {
        dispatch(uiActions.showError("Delete payment status failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getCheckStatuses(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/check-statuses`);
      if (status === 200) {
        dispatch(actions.setCheckStatuses(data));
      } else {
        dispatch(uiActions.showError("Get check statuses failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  createCheckStatus(values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.post(`/check-statuses`, values);
      if (status === 204 || status === 200) {
        dispatch(uiActions.showSuccess("Check status saved"));
        dispatch(actions.setCheckStatus(data));
        Navigation.go(AdminPages.users.path);
      } else {
        dispatch(uiActions.showError("failed to save check status"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getCheckStatus(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/check-statuses/${id}`);
      if (status === 200) {
        dispatch(actions.setCheckStatus(data));
      } else {
        dispatch(uiActions.showError("Get check status failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },

  updateCheckStatus(id, values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.put(
        `/check-statuses/${id}`,
        values,
      );
      if (status === 204 || status === 200) {
        dispatch(uiActions.showSuccess("Check status updated"));
        dispatch(dataActions.setCheckStatus(data));
        Navigation.go(AdminPages.users.path);
      } else {
        dispatch(uiActions.showError("failed to save check status"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  deleteCheckStatus(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.delete(`/check-statuses/${id}`);
      if (status === 204 || status === 200) {
        dispatch(uiActions.showSuccess("Check status deleted"));
        //Navigation.go(AdminPages.dashboard.path);
      } else {
        dispatch(uiActions.showError("Delete check status failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getMakes(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/makes`);
      if (status === 200) {
        dispatch(actions.setMakes(data));
      } else {
        dispatch(uiActions.showError("Get makes failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  // getMake(id): AppThunk {
  //   return async (dispatch) => {
  //     dispatch(uiActions.setLoading(true));
  //     const { data, status } = await authClient.get(`/makes/${id}`);
  //     if (status === 200) {
  //       dispatch(uiActions.showSuccess("Get make succeeded"));
  //       dispatch(actions.setMake(data));
  //     } else {
  //       dispatch(uiActions.showError("Get make failed"));
  //     }
  //     dispatch(uiActions.setLoading(false));
  //   };
  // },
  createMake(values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.post(`/makes`, values);
      if (status === 204 || status === 200) {
        dispatch(uiActions.showSuccess("Make saved"));
        dispatch(dataActions.getMakes());
        Navigation.go(AdminPages.makes.path);
      } else {
        dispatch(uiActions.showError("failed to save make"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  updateMake(id, values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.put(`/makes/${id}`, values);
      if (status === 204 || status === 200) {
        dispatch(uiActions.showSuccess("Make updated"));
        await dispatch(dataActions.getMakes());
        Navigation.go(AdminPages.makes.path);
      } else {
        dispatch(uiActions.showError("failed to update make"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  deleteMake(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.delete(`/makes/${id}`);
      if (status === 204 || status === 200) {
        dispatch(uiActions.showSuccess("Make deleted"));
        await dispatch(dataActions.getMakes());
      } else {
        dispatch(uiActions.showError("Delete make failed " + data.message));
      }
      Navigation.go(AdminPages.makes.path);

      dispatch(uiActions.setLoading(false));
    };
  },
  getChecks(query = ""): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/checks${query}`);
      if (status === 200) {
        dispatch(actions.setChecks(data));
      } else {
        dispatch(uiActions.showError("Get checks failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getCheck(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/checks/${id}`);
      if (status === 200) {
        dispatch(actions.setCheck(data));
      } else {
        dispatch(uiActions.showError("Get check failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  createCheck(values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.post(`/checks`, values);
      if (status === 204 || status === 200) {
        dispatch(uiActions.showSuccess("Check saved"));
        dispatch(actions.setCheck(data));
        // Navigation.go(AdminPages.checks.path);
      } else {
        dispatch(uiActions.showError("failed to save check"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  updateCheck(id, values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.put(`/checks/${id}`, values);
      if (status === 204 || status === 200) {
        dispatch(uiActions.showSuccess("Check updated"));
        dispatch(dataActions.setCheck(data));
        // Navigation.go(AdminPages.checks.path);
      } else {
        dispatch(uiActions.showError("failed to update check"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  deleteCheck(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.delete(`/checks/${id}`);
      if (status === 204 || status === 200) {
        dispatch(uiActions.showSuccess("Check deleted"));
        //Navigation.go(AdminPages.dashboard.path);
      } else {
        dispatch(uiActions.showError("Delete check failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getCredits(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/credits`);
      if (status === 200) {
        dispatch(actions.setCredits(data));
      } else {
        dispatch(uiActions.showError("Get credits failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getCredit(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/credits/${id}`);
      if (status === 200) {
        dispatch(actions.setCredit(data));
      } else {
        dispatch(uiActions.showError("Get credit failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  createCredit(values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.post(`/credits`, values);
      if (status === 204 || status === 200) {
        dispatch(uiActions.showSuccess("Credit saved"));
        dispatch(actions.setCredit(data));
        // Navigation.go(AdminPages.credits.path);
      } else {
        dispatch(uiActions.showError("failed to save credit"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  updateCredit(id, values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.put(`/credits/${id}`, values);
      if (status === 204 || status === 200) {
        dispatch(uiActions.showSuccess("Credit updated"));
        dispatch(dataActions.setCredit(data));
        // Navigation.go(AdminPages.credits.path);
      } else {
        dispatch(uiActions.showError("failed to update credit"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  deleteCredit(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.delete(`/credits/${id}`);
      if (status === 204 || status === 200) {
        dispatch(uiActions.showSuccess("Credit deleted"));
        //Navigation.go(AdminPages.dashboard.path);
      } else {
        dispatch(uiActions.showError("Delete credit failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getDealerships(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/dealerships`);
      if (status === 200) {
        dispatch(actions.setDealerships(data));
      } else {
        dispatch(uiActions.showError("Get dealerships failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getDealership(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/dealerships/${id}`);
      if (status === 200) {
        dispatch(actions.setActiveDealership(data));
      } else {
        dispatch(uiActions.showError("Get dealership failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getDealershipDetails(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(
        `/dealerships/${id}/details`,
      );
      if (status === 200) {
        dispatch(actions.setDealershipDetails(data));
      } else {
        dispatch(uiActions.showError("Get dealership details failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  createDealership(values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.post(`/dealerships`, values);
      if (status === 204 || status === 200) {
        dispatch(uiActions.showSuccess("Dealership saved"));
        dispatch(actions.setActiveDealership(data));
        Navigation.go(AdminPages.dealerships.path);
      } else {
        dispatch(uiActions.showError("failed to save dealership"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  updateDealership(id, values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.put(`/dealerships/${id}`, values);
      if (status === 204 || status === 200) {
        dispatch(uiActions.showSuccess("Dealership updated"));
        Navigation.go(AdminPages.dealerships.path);
      } else {
        dispatch(uiActions.showError("failed to update dealership"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  deleteDealership(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.delete(`/dealerships/${id}`);
      if (status === 204 || status === 200) {
        // Navigation.go(AdminPages.dealerships.path);
        dispatch(dataActions.getDealerships());
        dispatch(uiActions.showSuccess("Dealership deleted"));
      } else {
        dispatch(
          uiActions.showError("Delete dealership failed " + data.message),
        );
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getJobs(query = ""): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/jobs${query}`);
      if (status === 200) {
        if (query === "") dispatch(dataActions.getJobStats());
        dispatch(actions.setJobs(data));
      } else {
        dispatch(uiActions.showError("Get jobs failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getJobStats(): AppThunk {
    return async (dispatch) => {
      const { data, status } = await authClient.get(`/job_stats`);
      if (status === 200) {
        dispatch(actions.setJobStats(data));
      } else {
        dispatch(uiActions.showError("Get jobs stats failed"));
      }
    };
  },
  getJob(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/jobs/${id}`);
      if (status === 200) {
        dispatch(actions.setActiveJob(data));
      } else {
        dispatch(uiActions.showError("Get job failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  createJob(values, print = false): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.post(`/jobs`, values);
      if (status === 204 || status === 200) {
        dispatch(uiActions.showSuccess("Job saved"));
        if (print) {
          dispatch(dataActions.printJobInvoice(data.id));
        }
        Navigation.go(AdminPages.jobs.path);
      } else {
        dispatch(
          uiActions.showError(
            data?.message ? data.message : "Failed to save job",
          ),
        );
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  updateJob(id, values, print = false): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      console.log("IN FE Update Job");
      console.time();
      const { status, data } = await authClient.put(`/jobs/${id}`, values);
      console.log("IN FE after Update Job");
      console.timeEnd();
      console.time();
      if (status === 204 || status === 200) {
        dispatch(uiActions.showSuccess("Job updated"));
        dispatch(dataActions.getJobs());
        console.log("IN FE after get Jobs");
        console.timeEnd();
        console.time();
        if (print) {
          dispatch(dataActions.printJobInvoice(id));
          console.log("IN FE after printJobInvoice");
          console.timeEnd();
          console.time();
        }
        Navigation.go(AdminPages.jobs.path);
        console.log("IN FE after navigations.go");
        console.timeEnd();
      } else {
        dispatch(
          uiActions.showError(
            data?.message ? data.message : "Failed to update job",
          ),
        );
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  bulkUpdateJobs(values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.put(`/jobs/bulk_update`, values);
      if (status === 204 || status === 200) {
        dispatch(dataActions.getJobs());
      } else {
        dispatch(uiActions.showError("Failed to bulk update job"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  deleteJob(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.delete(`/jobs/${id}`);
      if (status === 204 || status === 200) {
        dispatch(uiActions.showSuccess("Job deleted"));
        dispatch(dataActions.getJobs());
      } else {
        dispatch(uiActions.showError("Delete job failed " + data.message));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  printJobInvoice(id): AppThunk {
    return async (dispatch, getState) => {
      dispatch(uiActions.setLoading(true));

      window.open(
        `${REACT_APP_API_URL}jobs/${id}/print?token=${getState().auth.token}`,
        "_blank",
      );
      // if (status === 204 || status === 200) {
      //   dispatch(uiActions.showSuccess("Job printed"));
      //   // Navigation.go(AdminPages.jobs.path);
      // } else {
      //   dispatch(uiActions.showError("print job failed"));
      // }
      dispatch(uiActions.setLoading(false));
    };
  },
  printJobsBulk(query, startDate, endDate): AppThunk {
    return async (dispatch, getState) => {
      dispatch(uiActions.setLoading(true));
      window.open(
        `${REACT_APP_API_URL}jobs/bulk_print/?${query}&start_date=${startDate}&end_date=${endDate}&token=${
          getState().auth.token
        }`,
        "_blank",
      );
      dispatch(uiActions.setLoading(false));
    };
  },
  printReceiptOfCheck(id): AppThunk {
    return async (dispatch, getState) => {
      dispatch(uiActions.setLoading(true));

      window.open(
        `${REACT_APP_API_URL}checks/${id}/print?token=${getState().auth.token}`,
        "_blank",
      );
      // if (status === 204 || status === 200) {
      //   dispatch(uiActions.showSuccess("Job printed"));
      //   // Navigation.go(AdminPages.jobs.path);
      // } else {
      //   dispatch(uiActions.showError("print job failed"));
      // }
      dispatch(uiActions.setLoading(false));
    };
  },
  getRoles(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/roles`);
      if (status === 200) {
        dispatch(actions.setRoles(data));
      } else {
        dispatch(uiActions.showError("Get roles failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getRole(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/roles/${id}`);
      if (status === 200) {
        dispatch(actions.setRole(data));
      } else {
        dispatch(uiActions.showError("Get role failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  createRole(values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.post(`/roles`, values);
      if (status === 204 || status === 200) {
        dispatch(uiActions.showSuccess("Role saved"));
        dispatch(actions.setRole(data));
        // Navigation.go(AdminPages.roles.path);
      } else {
        dispatch(uiActions.showError("failed to save role"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  updateRole(id, values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.put(`/roles/${id}`, values);
      if (status === 204 || status === 200) {
        dispatch(uiActions.showSuccess("Role updated"));
        dispatch(dataActions.setRole(data));
        // Navigation.go(AdminPages.roles.path);
      } else {
        dispatch(uiActions.showError("failed to update role"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  deleteRole(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.delete(`/roles/${id}`);
      if (status === 204 || status === 200) {
        dispatch(uiActions.showSuccess("Role deleted"));
        //Navigation.go(AdminPages.dashboard.path);
      } else {
        dispatch(uiActions.showError("Delete role failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getUsers(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/users`);
      if (status === 200) {
        dispatch(actions.setUsers(data));
      } else {
        dispatch(uiActions.showError("Get users failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getUser(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/users/${id}`);
      if (status === 200) {
        dispatch(actions.setUser(data));
      } else {
        dispatch(uiActions.showError("Get user failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  createUser(values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.post(`/users`, values);
      if (status === 204 || status === 200) {
        dispatch(uiActions.showSuccess("User saved"));
        dispatch(actions.setUser(data));
        // Navigation.go(AdminPages.users.path);
      } else {
        dispatch(uiActions.showError("failed to save user"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  updateUser(id, values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.put(`/users/${id}`, values);
      if (status === 204 || status === 200) {
        dispatch(uiActions.showSuccess("User updated"));
        dispatch(dataActions.setUser(data));
        // Navigation.go(AdminPages.users.path);
      } else {
        dispatch(uiActions.showError("failed to update user"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  deleteUser(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.delete(`/users/${id}`);
      if (status === 204 || status === 200) {
        dispatch(uiActions.showSuccess("User deleted"));
        //Navigation.go(AdminPages.dashboard.path);
      } else {
        dispatch(uiActions.showError("Delete user failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getUserRoles(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/users/${id}/roles`);
      if (status === 200) {
        dispatch(actions.setUserRoles(data));
      } else {
        dispatch(uiActions.showError("Get user roles failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },

  getServices(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/services`);
      if (status === 200) {
        dispatch(actions.setServices(data));
      } else {
        dispatch(uiActions.showError("Get services failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getService(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/services/${id}`);
      if (status === 200) {
        dispatch(uiActions.showSuccess("Get service succeeded"));
        dispatch(actions.setService(data));
      } else {
        dispatch(uiActions.showError("Get service failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  createService(values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.post(`/services`, values);
      if (status === 204 || status === 200) {
        dispatch(uiActions.showSuccess("Service created"));
        dispatch(actions.setService(data));
        // Navigation.go(AdminPages.services.path);
      } else {
        dispatch(uiActions.showError("failed to create service"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  updateService(id, values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.put(`/services/${id}`, values);
      if (status === 204 || status === 200) {
        dispatch(uiActions.showSuccess("Service updated"));
        dispatch(actions.setService(data));
        // Navigation.go(AdminPages.services.path);
      } else {
        dispatch(uiActions.showError("failed to update service"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  deleteService(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.delete(`/services/${id}`);
      if (status === 204 || status === 200) {
        dispatch(uiActions.showSuccess("Service deleted"));
        // Navigation.go(AdminPages.services.path);
      } else {
        dispatch(uiActions.showError("Delete service failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  applyCheck(values, update): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.put(`/checks`, values);
      if (status === 204 || status === 200) {
        dispatch(uiActions.showSuccess("Check applied"));
        if (update === "updateDealership") {
          dispatch(dataActions.getDealership(values.dealership_id));
        } else if (update === "updateJobs") {
          dispatch(dataActions.getJobs());
        }
        // Navigation.go(AdminPages.services.path);
      } else if (status === 400) {
        dispatch(uiActions.showError("Missing required info"));
      } else {
        dispatch(uiActions.showError("Check application failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  applyCredit(values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.post(`/credits`, {
        ...values,
        check_status_id: 2,
      });
      if (status === 204 || status === 200) {
        dispatch(uiActions.showSuccess("Credit applied"));
        dispatch(dataActions.getDealershipDetails(values.dealership_id));
        // Navigation.go(AdminPages.services.path);
      } else {
        dispatch(uiActions.showError("Credit application failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  removeCheckFromJob(check_id, job_id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.delete(
        `/jobs/${job_id}/checks/${check_id}`,
      );
      if (status === 204 || status === 200) {
        dispatch(uiActions.showSuccess("Check removed"));
        dispatch(dataActions.getJob(job_id));
        // Navigation.go(AdminPages.services.path);
      } else {
        dispatch(uiActions.showError("Check removal failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  editCheckNumber(check_id, values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.post(
        `/checks/${check_id}/edit_check_number`,
        { check_number: values },
      );
      if (status === 200) {
        dispatch(uiActions.showSuccess("Successfully edited check number"));
        dispatch(actions.editCheckNumSuccess(true));
        setTimeout(() => {
          dispatch(actions.editCheckNumSuccess(false));
        }, 10);
      } else {
        dispatch(uiActions.showError("Edit check number failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
};
