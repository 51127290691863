import React from "react";
import { Field } from "formik";
import { TextField } from "formik-mui";
import { InputAdornment } from "@mui/material";
import { DollarFormatter } from "./NumberFormatter";

export function FormikDollarField({ label, fieldName, disabled = false }) {
  return (
    <Field
      component={TextField}
      fullWidth
      disabled={disabled}
      name={fieldName}
      label={label}
      InputProps={{
        inputComponent: DollarFormatter,
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
    />
  );
}
