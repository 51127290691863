import React, { useEffect } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useDispatch, useSelector } from "react-redux";
import AdminPages from "../index";
import { RootStyled } from "./DealershipsPage.styles";
import { dataActions } from "../../../state/data/actions";
import { dataSelectors } from "../../../state/data/selectors";
import { objToArray } from "../../../state";
import { Navigation } from "../../../lib";
import { dealershipColumns } from "../../../state";

export const DealershipsPage = () => {
  const dispatch = useDispatch();
  const dealerships = useSelector(dataSelectors.dealerships);

  useEffect(() => {
    dispatch(dataActions.getDealerships());
  }, [dispatch]);

  return (
    <RootStyled>
      <DataGridPro
        rows={objToArray(dealerships)}
        columns={dealershipColumns}
        onRowClick={(params) => {
          Navigation.go(
            AdminPages.editDealership.path.replace(":id", params.row.id),
          );
        }}
        sx={{
          "& .MuiDataGrid-row:hover": {
            cursor: "pointer",
          },
        }}
      />
    </RootStyled>
  );
};
