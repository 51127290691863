import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
} from "@mui/material";
import { Transition } from "./Transition";
import { formatDecimal } from "../../../lib";
import { dataActions, PaymentStatusType } from "../../../state";
import { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
interface StatusType {
  id: number;
  label: string;
}
const ConfirmDialog = ({
  modal,
  onClose,
  selectedRows,
  selectionModel,
  setSelectionModel,
  dealership_id,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState<boolean>(!!modal);
  const [status, setStatus] = useState<StatusType | null>(null);
  const [check, setCheck] = useState<string>();
  const [consent, setConsent] = useState(false);
  const [dateValue, setDateValue] = useState<Dayjs | null>(null);

  const handleClose = (e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.stopPropagation();
    setStatus(null);
    setCheck("");
    setConsent(false);
    onClose ? onClose(null) : setOpen(false);
  };

  const totalBill = () =>
    selectedRows.reduce(
      (sum, row) => sum + row.alpine_fee + row.mv_fee + row.sales_tax,
      0,
    );

  useEffect(() => {
    setStatus(null);
    setDateValue(null);
    setCheck("");
    setConsent(false);
    setOpen(!!modal);
  }, [modal]);

  async function handleSubmit() {
    if (status?.id === PaymentStatusType.PAID) {
      await dispatch(
        dataActions.applyCheck(
          {
            check_date: dateValue,
            check_number: check,
            dealership_id: dealership_id,
            jobs: selectionModel,
            amount: totalBill(),
          },
          "updateJobs",
        ),
      );
    } else {
      await dispatch(
        dataActions.bulkUpdateJobs({
          status_id: status?.id,
          jobs: selectionModel,
        }),
      );
    }

    setSelectionModel([]);
    handleClose();
  }
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => onClose?.(null)}
    >
      <DialogTitle>Update payment status</DialogTitle>

      <DialogContent>
        <DialogContentText sx={{ mb: 3 }}>
          {selectedRows.length > 1
            ? `${selectedRows.length}  jobs selected`
            : `${selectedRows.length}  job selected`}
        </DialogContentText>
        <Grid container spacing={3} alignItems={"center"} sx={{ mb: 3 }}>
          <Grid container sx={{ ml: 3, mt: 2, mr: 2 }}>
            <Autocomplete
              id="combo-box"
              key={Date.now()}
              options={[
                { id: 1, label: "Not Started" },
                { id: 2, label: "Invoiced" },
                { id: 4, label: "Paid" },
              ]}
              // sx={{ width: 300 }}
              fullWidth={true}
              value={status || undefined}
              onChange={(event: any, newValue: StatusType | null) => {
                if (newValue) {
                  setStatus(newValue);
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label="Select Status" />
              )}
            />
          </Grid>
          {status?.id === 4 && (
            <Grid container sx={{ ml: 2, mt: 2 }} spacing={1} columns={12}>
              <Grid item xs={6}>
                <TextField
                  label="Check Number"
                  name="check_number"
                  fullWidth
                  variant="outlined"
                  onChange={(e) => {
                    setCheck(e.target.value);
                  }}
                  required={true}
                />
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Check date"
                    value={dateValue}
                    onChange={(newValue) => {
                      setDateValue(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField required={true} {...params} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item>
                <DialogContentText sx={{ mb: 3, mt: 2, ml: 1 }}>
                  Total for selected jobs:{" "}
                  <strong> ${formatDecimal(totalBill())}</strong>
                </DialogContentText>
              </Grid>
            </Grid>
          )}
          <Grid container sx={{ ml: 3, mt: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={consent}
                  onChange={(event, value) => {
                    setConsent(value);
                  }}
                />
              }
              label="I understand that this bulk action and it cannot be undone."
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          color="inherit"
          variant="contained"
          sx={{ minWidth: "140px" }}
          onClick={handleClose}
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          color={"primary"}
          sx={{ minWidth: "140px" }}
          onClick={handleSubmit}
          disabled={
            consent
              ? status
                ? status?.id === 4
                  ? check && dateValue
                    ? false
                    : true
                  : false
                : true
              : true
          }
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
