import React from "react";
import { SectionHeaderText } from "./Form.styles";
export function SectionHeader(props: {
  label: string;
  variant?: any;
  gutterBottom?: boolean;
}) {
  return (
    <SectionHeaderText
      variant={props.variant}
      gutterBottom={props.gutterBottom}
    >
      {props.label}
    </SectionHeaderText>
  );
}
