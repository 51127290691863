import React, { useEffect, useState } from "react";
import { Grid, Button, Card, CardContent } from "@mui/material";
import {
  arrayToObjById,
  dataSelectors,
  useDispatch,
  useSelector,
} from "../../../state";
import { TextField } from "formik-mui";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { dataActions } from "../../../state";
import { useParams } from "react-router";
import { RootStyled, GridStyled } from "./MakePage.styles";

const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
});

export const MakePage = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [editMode, setEditMode] = useState(false);
  const makes = useSelector(dataSelectors.makes);
  const [initialValues, setInitialValues] = useState({
    name: "",
  });
  useEffect(() => {
    if (params.id) {
      dispatch(dataActions.getMakes);
    }
  }, [dispatch, params.id]);

  useEffect(() => {
    if (params.id) {
      setInitialValues(arrayToObjById(makes, "id")[params.id]);
      setEditMode(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [makes]);

  const handleSubmit = (values, { setSubmitting }) => {
    if (editMode) {
      dispatch(dataActions.updateMake(params.id, values));
    } else {
      dispatch(dataActions.createMake(values));
    }
    setSubmitting(false);
  };

  return (
    <RootStyled>
      <Card>
        <CardContent sx={{ pt: 6 }}>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              isSubmitting,
              errors,
              touched,
              values,
              handleChange,
              setFieldValue,
            }) => (
              <Form>
                <Grid container>
                  <GridStyled item>
                    <Field
                      component={TextField}
                      fullWidth
                      name="name"
                      label="Name"
                      required
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </GridStyled>
                </Grid>

                <Grid container justifyContent="flex-end">
                  <GridStyled
                    item
                    sx={{ display: "flex", justifyContent: "end" }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                  </GridStyled>
                </Grid>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </RootStyled>
  );
};
