import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { arrayToObjById, useDispatch } from "../../state";
import { useFormikContext } from "../../lib";
interface NameId {
  name: string;
  id: number;
}

interface NameIdAutocompleteProps {
  getOptionsAction: () => void;
  options: NameId[];
  initialValue?: number | null;
  label: string;
  name: string;
  fullWidth?: boolean;
  required?: boolean;
  disabled?: boolean;
  getOptionDisabled?: (option: any) => boolean;
}

export default function NameIdAutocomplete(props: NameIdAutocompleteProps) {
  const [open, setOpen] = React.useState(false);
  const options: NameId[] = props.options || [];
  const optionsObj = arrayToObjById(options, "id");
  const loading = open && options.length === 0;
  const dispatch = useDispatch();
  const { getOptionsAction } = props;

  useEffect(() => {
    (async () => {
      dispatch(getOptionsAction());
    })();
  }, [dispatch, getOptionsAction]);
  useEffect(() => {
    form.setFieldValue(props.name, props.initialValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.initialValue]);

  const form: any = useFormikContext();
  const val =
    form && form.values && form.values[props.name]
      ? form.values[props.name]
      : "";
  const { touched, errors } = form;

  return (
    <Autocomplete
      disabled={props.disabled}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      getOptionDisabled={props.getOptionDisabled}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => {
        return option === value;
      }}
      getOptionLabel={(option) => {
        return optionsObj[option]?.name || "";
      }}
      options={options.map((opt) => opt.id)}
      loading={loading}
      onChange={(event, value) => {
        form.setFieldValue(props.name, value);
      }}
      value={val}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          required={props.required}
          fullWidth={props.fullWidth}
          error={touched[props.name] && Boolean(errors[props.name])}
          helperText={touched[props.name] && errors[props.name]}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
