import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import {
  listsActions,
  listsSelectors,
  useDispatch,
  useSelector,
} from "../../state";
import { ListboxComponent, StyledPopper } from "../inputs/AutocompleteListBox";

export const CheckAutocompleteFilter = (props: any) => {
  const [open, setOpen] = useState(false);
  const checkNumbers: any = useSelector(listsSelectors.checkNumberList);
  const loading = open && checkNumbers?.length === 0;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listsActions.getCheckNumberList());
  }, [dispatch]);

  return (
    <Autocomplete
      sx={props.sx}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => {
        return option === value;
      }}
      getOptionLabel={(option: any) => {
        return option;
      }}
      onChange={(event, value) => {
        if (props.onChange) {
          props.onChange(event, value);
        }
      }}
      PopperComponent={StyledPopper}
      ListboxComponent={ListboxComponent}
      renderOption={(props, option) => [props, option] as React.ReactNode}
      options={[...checkNumbers]}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Check Number"
          InputProps={{
            ...params.InputProps,

            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
