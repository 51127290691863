import * as React from "react";
import { useEffect } from "react";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useFormikContext } from "../../lib";
export default function ActiveInactiveToggle(props) {
  const form: any = useFormikContext();
  const [value, setValue] = React.useState<boolean | null>(
    form.values?.is_active,
  );
  useEffect(() => {
    setValue(form.values?.is_active);
  }, [form.values]);

  const handleActiveChange = (
    event: React.MouseEvent<HTMLElement>,
    value: boolean | null,
  ) => {
    if (value !== null) {
      setValue(value);
      console.log("value", event, value);
      form.setFieldValue("is_active", value);
    }
  };

  return (
    <ToggleButtonGroup
      sx={{ width: "100%" }}
      value={value}
      exclusive
      onChange={handleActiveChange}
      aria-label="text alignment"
    >
      <ToggleButton color="primary" sx={{ flex: 1 }} value={true}>
        Active
      </ToggleButton>

      <ToggleButton color="primary" sx={{ flex: 1 }} value={false}>
        Inactive
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
