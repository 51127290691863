import { Chip } from "@mui/material";

export const StatusLabel = ({ status }: { status: string }) => {
  const statusColor = {
    "MISSING PAPERWORK": "#ffc107",
    COMPLETED: "#28a745",
    "IN PROCESS": "#17a2b8",
    REJECTED: "#dc3545",
  }[status];

  return (
    <Chip
      style={{
        backgroundColor: statusColor,
        color: "#fff",
        height: 23,
        width: "fit-content",
        whiteSpace: "nowrap",
        padding: "3px 10px",
      }}
      label={status}
    />
  );
};
