import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import {
  listsActions,
  listsSelectors,
  useDispatch,
  useSelector,
} from "../../state";
import { useFormikContext } from "../../lib";

export default function DealershipAutocomplete(props: any) {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const dealershipList: any = useSelector(listsSelectors.dealershipsList);
  const loading = open && dealershipList.length === 0;
  const { initialValues, setFieldValue }: any = useFormikContext();
  const [value, setValue]: any = useState(initialValues?.dealership_id);

  useEffect(() => {
    dispatch(listsActions.getDealershipList());
  }, [dispatch]);

  useEffect(() => {
    const _value = dealershipList?.find(
      (s) => s.id === initialValues?.dealership_id,
    );
    if (_value) {
      setValue(_value);
    } else {
      setValue("");
    }
  }, [initialValues?.dealership_id, dealershipList]);
  return (
    <Autocomplete
      sx={props.sx}
      disabled={props.disabled}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      getOptionLabel={(option: any) => option?.dealership_name || ""}
      onChange={(event, value) => {
        setFieldValue("dealership_id", value?.id);
        setValue(value);
        if (props.onChange) {
          props.onChange(event, value?.id);
        }
      }}
      options={dealershipList}
      value={value}
      loading={loading}
      data-dealership_id={value}
      renderInput={(params) => (
        <TextField
          required
          {...params}
          label="Dealership"
          InputProps={{
            ...params.InputProps,

            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
