import React, { useEffect } from "react";
import { Button } from "@mui/material";
import { RootStyle } from "./HomePage.styles";
import LandingHero from "../../components/_home/LandingHero";
import { Link } from "react-router-dom";
import { Navigation } from "../../lib";
import { useSelector } from "react-redux";
import { authSelectors } from "../../state";

function _HomePage() {
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  useEffect(() => {
    if (isLoggedIn === true) {
      Navigation.go("/admin/jobs");
    } else {
      Navigation.go("/auth/login");
    }
  }, [isLoggedIn]);

  return (
    <RootStyle
      title="The starting point for your next project | Minimal-UI"
      id="move_top"
    >
      <Button
        sx={{
          color: "#000",
          backgroundColor: "#E8E8E8",
          position: "absolute",
          width: 150,
          top: 60,
          right: 60,
          zIndex: 99,
        }}
      >
        <Link to="/auth/login">Login</Link>
      </Button>
      <LandingHero />
    </RootStyle>
  );
}

export const HomePage = React.memo(_HomePage);
