import { AppThunk } from "..";
import { authClient, uiActions } from "../states";
import { lists } from "./state";

const { actions } = lists;

export const listsActions = {
  ...actions,
  getCustomerNameList(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/customer_names`);
      if (status === 200) {
        dispatch(actions.setCustomerNameList(data));
      } else {
        dispatch(uiActions.showError("Get customer name dropdown failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getDealershipList(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/dealership_list`);
      if (status === 200) {
        dispatch(actions.setDealershipList(data));
      } else {
        dispatch(uiActions.showError("Get dealership dropdown failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getCheckNumberList(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/check_numbers`);
      if (status === 200) {
        dispatch(actions.setCheckNumberList(data));
      } else {
        dispatch(uiActions.showError("Get customer name dropdown failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
};
