import { styled } from "@mui/material/styles";

export const RootStyled = styled(`div`)(({ theme }) => ({
  height: "calc(100vh - 72px)",
  display: "flex",
  flexDirection: "column",
  margin: 0,
  [theme.breakpoints.down("sm")]: {
    height: "calc(100vh - 2px)",
    margin: 4,
  },
}));

export const ActionBox = styled(`div`)(({ theme }) => ({
  position: "absolute",
  right: 56,
  top: 18,
  [theme.breakpoints.down("sm")]: {
    right: 6,
    top: 69,
  },
}));
