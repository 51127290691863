import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import {
  listsActions,
  listsSelectors,
  useDispatch,
  useSelector,
} from "../../state";
import { ListboxComponent, StyledPopper } from "../inputs/AutocompleteListBox";

export const CustomerAutocompleteFilter = (props: any) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const _customerNames = useSelector(listsSelectors.customerNameList);
  const loading = open && _customerNames.length === 0;

  useEffect(() => {
    dispatch(listsActions.getCustomerNameList());
  }, [dispatch]);

  return (
    <Autocomplete
      sx={props.sx}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => {
        return option === value;
      }}
      getOptionLabel={(option: any) => {
        return option;
      }}
      onChange={(event, value) => {
        if (props.onChange) {
          props.onChange(event, value);
        }
      }}
      options={_customerNames}
      PopperComponent={StyledPopper}
      ListboxComponent={ListboxComponent}
      loading={loading}
      renderOption={(props, option) => [props, option] as React.ReactNode}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Customer Name"
          InputProps={{
            ...params.InputProps,

            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
