import { styled } from "@mui/material/styles";

export const RootStyled = styled(`div`)(({ theme }) => ({
  height: "calc(100vh - 72px)",
  display: "flex",
  flexDirection: "column",
  margin: 8,
  [theme.breakpoints.down("sm")]: {
    height: "calc(100vh - 2px)",
    margin: 4,
  },
}));

export const ActionBox = styled(`div`)(({ theme }) => ({}));
