import { Chip } from "@mui/material";

export const PaymentStatusLabel = ({ status }: { status: string }) => {
  const statusColor = {
    "PARTIALLY PAID": "#ffc107",
    PAID: "#28a745",
    "NOT STARTED": "#17a2b8",
    INVOICED: "#dc3545",
  }[status];

  return (
    <Chip
      style={{
        border: `1px solid ${statusColor}`,
        backgroundColor: "#fff",
        height: 23,
        width: "fit-content",
        whiteSpace: "nowrap",
        padding: "3px 10px",
      }}
      label={status}
    />
  );
};
