import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Card, CardContent, Typography } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { dataSelectors, useDispatch, useSelector } from "../../../state";
import { dataActions } from "../../../state";
import { RootStyled } from "./JobPage.styles";
import { defaultJob } from "../../../state/types";
import JobForm from "./JobForm";
const validationSchema = yup.object({
  service_id: yup.number().required(),
  invoice_date: yup.date().required().typeError("Invoice date is required"),
});

const JobPage = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [editMode, setEditMode] = useState(false);
  const activeJob = useSelector(dataSelectors.activeJob);
  const [initialValues, setInitialValues]: any = useState(defaultJob);

  useEffect(() => {
    if (params?.id) {
      dispatch(dataActions.getJob(params.id));
    }
  }, [dispatch, params?.id]);

  useEffect(() => {
    dispatch(dataActions.getStatuses());
    dispatch(dataActions.getMakes());
    dispatch(dataActions.getServices());
  }, [dispatch]);

  useEffect(() => {
    if (params?.id && activeJob) {
      setInitialValues(activeJob);
      setEditMode(true);
    }
  }, [activeJob, params?.id]);

  const handleSubmit = (values, { setSubmitting }) => {
    if (editMode) {
      dispatch(dataActions.updateJob(params.id, values));
    } else {
      dispatch(dataActions.createJob(values));
    }
    setSubmitting(false);
  };

  return (
    <RootStyled>
      <Card>
        <CardContent sx={{ pt: 6 }}>
          <Typography variant="body1" gutterBottom>
            {params.id
              ? `Created by ${initialValues?.created_by_user?.first_name}  ${initialValues?.created_by_user?.last_name}   •    Last edited by ${initialValues?.last_edited_by_user?.first_name}   ${initialValues?.last_edited_by_user?.last_name}  `
              : ""}
          </Typography>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <JobForm editMode={editMode} params={params} />
          </Formik>
        </CardContent>
      </Card>
    </RootStyled>
  );
};
export default JobPage;
