import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ListState {
  customerNameList: any[];
  checkNumberList: any[];
  dealershipsList: any[];
}

const initialState: ListState = {
  customerNameList: [],
  checkNumberList: [],
  dealershipsList: [],
};

export const lists = {
  ...createSlice({
    name: "lists",
    initialState,
    reducers: {
      setCustomerNameList(state, { payload }: PayloadAction<any>) {
        state.customerNameList = payload;
      },
      setDealershipList(state, { payload }: PayloadAction<any>) {
        state.dealershipsList = payload;
      },
      setCheckNumberList(state, { payload }: PayloadAction<any>) {
        state.checkNumberList = payload;
      },
    },
  }),
  persist: false,
};
