import { Fragment, useState } from "react";
import {
  Grid,
  styled,
  TextField,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from "@mui/material/";
import { FilterModeler } from "../../../lib/utils/FilterModeler";
import {
  CheckAutocompleteFilter,
  CustomerAutocompleteFilter,
  DealershipAutocompleteFilter,
  StateAutocompleteFilter,
} from "../../../components/filters";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import {
  DateRange,
  DateRangePicker,
} from "@mui/x-date-pickers-pro/DateRangePicker";

export default function FilterRow(props: any) {
  const [value, setValue] = useState<DateRange<any>>([null, null]);

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(147,147,147,0.87)",
      fontSize: 12,
      marginBottom: 0,
      padding: 0,
    },
  }));
  return (
    <Grid
      sx={{ mb: 3 }}
      container
      justifyContent={"space-between"}
      spacing={1}
      wrap="nowrap"
    >
      <Grid item xs={12} md={3}>
        <DealershipAutocompleteFilter
          setFilterModel={props.setFilterModel}
          filterModel={props.filterModel}
          onChange={(event: any, value: any) => {
            let newFilterModel = FilterModeler.removeFilter(
              "dealership_id",
              props.filterModel,
            );
            if (value) {
              newFilterModel = FilterModeler.addFilter(
                {
                  columnField: "dealership_id",
                  operatorValue: "equals",
                  value: value,
                },
                newFilterModel,
              );
            }
            props.setFilterModel(newFilterModel);
          }}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <StateAutocompleteFilter
          setFilterModel={props.setFilterModel}
          filterModel={props.filterModel}
          onChange={(event: any, value: any) => {
            let newFilterModel = FilterModeler.removeFilter(
              "state",
              props.filterModel,
            );
            if (value) {
              newFilterModel = FilterModeler.addFilter(
                {
                  columnField: "state",
                  operatorValue: "equals",
                  value: value,
                },
                newFilterModel,
              );
            }
            props.setFilterModel(newFilterModel);
          }}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <CustomerAutocompleteFilter
          setFilterModel={props.setFilterModel}
          filterModel={props.filterModel}
          onChange={(event: any, value: any) => {
            let newFilterModel = FilterModeler.removeFilter(
              "customer_name",
              props.filterModel,
            );
            if (value) {
              newFilterModel = FilterModeler.addFilter(
                {
                  columnField: "customer_name",
                  operatorValue: "contains",
                  value: value,
                },
                newFilterModel,
              );
            }
            props.setFilterModel(newFilterModel);
          }}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <CheckAutocompleteFilter
          setFilterModel={props.setFilterModel}
          filterModel={props.filterModel}
          onChange={(event: any, value: any) => {
            let newFilterModel = FilterModeler.removeFilter(
              "check_number",
              props.filterModel,
            );
            if (value) {
              newFilterModel = FilterModeler.addFilter(
                {
                  columnField: "check_number",
                  operatorValue: "contains",
                  value: value,
                },
                newFilterModel,
              );
            }
            props.setFilterModel(newFilterModel);
          }}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          // localeText={{ start: "Date range", end: "" }}
        >
          <DateRangePicker
            value={value}
            onChange={(newValue) => {
              setValue(newValue);
              let newFilterModel = FilterModeler.removeFilter(
                "date_range",
                props.filterModel,
              );
              if (newValue[1] !== null) {
                setValue(newValue);
                newFilterModel = FilterModeler.addFilter(
                  {
                    columnField: "date_range",
                    value: {
                      start_date: new Date(newValue[0]?.$d),
                      end_date: new Date(newValue[1]?.$d),
                    },
                  },
                  newFilterModel,
                );
                props.setFilterModel(newFilterModel);
              }
            }}
            renderInput={(startProps, endProps) => (
              <Fragment>
                <LightTooltip
                  open={true}
                  title="Invoice date range"
                  placement="top-start"
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, -9],
                        },
                      },
                    ],
                  }}
                >
                  <TextField {...startProps} sx={{ minWidth: "123px" }} />
                </LightTooltip>
                &nbsp;to&nbsp;
                <TextField {...endProps} sx={{ minWidth: "123px" }} />
              </Fragment>
            )}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
}
