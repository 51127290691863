import React, { useEffect } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AdminPages from "../index";
import { IconButton } from "@mui/material";
import { RootStyled } from "./MakesPage.styles";
import { Create } from "@mui/icons-material";
import { dataActions } from "../../../state/data/actions";
import { dataSelectors } from "../../../state/data/selectors";
import { ConfirmDeleteDialog } from "../../../components/dialogs/ConfirmDeleteDialog";

export const MakesPage = () => {
  const dispatch = useDispatch();
  const makes = useSelector(dataSelectors.makes);
  useEffect(() => {
    dispatch(dataActions.getMakes());
  }, [dispatch]);

  const columns = [
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <>
          <Link
            to={AdminPages.editMakes.path.replace(":id", params.id.toString())}
          >
            <IconButton color="primary" size="small">
              <Create />
            </IconButton>
          </Link>

          <ConfirmDeleteDialog
            handleConfirm={() => dispatch(dataActions.deleteMake(params.id))}
          />
        </>
      ),
    },
    { field: "id", headerName: "Id", width: 150 },
    { field: "name", headerName: "Name", width: 150 },
  ];
  return (
    <RootStyled>
      <DataGridPro rows={makes} columns={columns} />
    </RootStyled>
  );
};
