import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "../types";

//TODO: adjust for project
const ROLES = {
  SUPER_ADMIN: "super_admin",
  ADMIN: "admin",
};

const _state = (state: AppState) => state.auth;
const _roles = (state: AppState) => state.auth.roles ?? [];
const _isAdmin = createSelector(_roles, (roles) =>
  roles.includes(ROLES.SUPER_ADMIN),
);
const _isLoggedIn = createSelector(_state, (state) => !!state.userId);
const jwt = createSelector(_state, (state) => state.token);
const _userId = createSelector(_state, (state) => state.userId ?? 0);
const _userName = createSelector(_state, (state) => state.userName ?? "");
const _userInfo = (state: AppState) =>
  state.auth.user || { first_name: "", last_name: "", email: "", phone: "" };

export const authSelectors = {
  isAdmin: _isAdmin,
  isLoggedIn: _isLoggedIn,
  roles: _roles,
  /** Selector used for routing */
  route: createSelector(_isLoggedIn, _roles, (isLoggedIn, roles) => ({
    isAdmin: roles.includes(ROLES.SUPER_ADMIN),
    isLoggedIn,
    roles,
  })),
  /** Selector used for getting the entire auth state. */
  state: createSelector(
    _isAdmin,
    _isLoggedIn,
    _roles,
    _userId,
    _userName,
    (isAdmin, isLoggedIn, roles, userId, userName) => ({
      isAdmin,
      isLoggedIn,
      roles,
      userId,
      userName,
    }),
  ),
  jwt: jwt,
  userId: _userId,
  userName: _userName,
  userInfo: _userInfo,
};

export const AuthSelectors = authSelectors;
