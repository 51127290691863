import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";

export const RootStyled = styled(`div`)(({ theme }) => ({
  height: "calc(100vh - 72px)",
}));

export const GridStyled = styled(Grid)(({ theme }) => ({
  width: "50%",
  minWidth: 330,
  padding: "0 2em 2em",
  "& .MuiSelect-select": {
    padding: 13,
    minHeight: 58,
    boxSizing: "border-box",
  },
}));
