import { Action, Slice, ThunkAction, ThunkDispatch } from "@reduxjs/toolkit";
// Local
import { AppState } from "./states";
import currency from "currency.js";

export type { AppState };

export interface AppStateInfo extends Slice {
  persist?: boolean;
  preload?: boolean;
  purge?: boolean;
}
/**
 * Type to return from async actions (redux-thunk).
 * `R` describes the return value of the thunk.
 * `E` describes the extra argument type given to the action thunk, e.g.
 * `(dispatch, getState, extraArgument) => {}`
 */
export type AppThunk<R = Promise<void>, E = unknown> = ThunkAction<
  R,
  AppState,
  E,
  Action<string>
>;
/**
 * Dispatch function for this application (AppState).
 * `E` describes the extra argument type given to the action thunk, e.g.
 * `(dispatch, getState, extraArgument) => {}`
 */
export type AppThunkDispatch<E = unknown> = ThunkDispatch<
  AppState,
  E,
  Action<string>
>;

export type FormErrors = Record<string, any>;

export interface ListState<T> {
  items: T[];
  // items: Record<string | number, T>;
  // order: any[];
  page: number;
  pagesTotal: number;
  total: number;
}

export interface User {
  id: number;
  email: string;
  phone: string;
  first_name: string;
  last_name: string;
  login_attempts: number;
  last_login: string;
  last_failed_login?: string;
}

export interface NameId {
  id: number;
  name: string;
}
export interface Status extends NameId {}

export interface CheckStatus extends NameId {}
export interface PaymentStatus extends NameId {}
export interface Make extends NameId {}

export interface Check {
  check_status: any;
  id: number;
  check_number?: string | null;
  amount?: number | null;
  dealership_id: number | null;
  check_status_id?: number | null;
  check_date?: string | null;
}
export interface Credit {
  id: number;
  amount?: number;
  dealership_id: number;
}
export interface Dealership {
  balance?: currency.Any;
  jobs: any[];
  id: number;
  dealership_name?: string;
  contact_name?: string;
  email_address?: string;
  address?: string;
  address_2?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  phone_1?: string;
  phone_2?: string;
  fax?: string;
  is_active: boolean;
}
export interface Job {
  id: number;
  invoice_number?: string;
  dealership_id?: number;
  customer_name?: string;
  status_id: number;
  vehicle_year?: number;
  make_id?: number;
  created_by_user_id: number;
  last_edited_by_user_id: number;
  last_six_of_vin?: string;
  miscellaneous?: string;
  license_plate?: string;
  customer_id?: string;
  state: string;
  customer_address?: string;
  service_id: number;
  check_id?: number;
  invoice_date?: string;
  alpine_fee?: number;
  mv_fee?: number;
  sales_tax?: number;
  payment_status_id?: number;
  paperwork_received_date?: string;
  paperwork_sent_to_dmv?: string;
  paperwork_estimated_return?: string;
  paperwork_returned_from_dmv?: string;
  paperwork_tracking?: string;
  rejected: number;
  rejection_reason?: string;
  rejection_sent_to_dmv_date?: string;
  rejection_estimated_return?: string;
  rejection_returned_from_dmv?: string;
  rejection_tracking?: string;
}
export interface Role {
  id: number;
  internal_name?: string;
  name?: string;
}
export interface JobStats {
  all: number;
  inProcess: number;
  completed: number;
  rejectedMissing: number;
  paid: number;
  unpaid: number;
}

export interface Service extends NameId {}

export interface UserRole {
  id: number;
  user_id: number;
  role_id: number;
}

export const defaultJob = {
  invoice_number: "",
  dealership_id: null,
  customer_name: "",
  miscellaneous: "",
  status_id: null,
  vehicle_year: null,
  make_id: null,
  created_by_user_id: null,
  last_edited_by_user_id: null,
  state: null,
  customer_address: "",
  service_id: null,
  invoice_date: new Date(),
  alpine_fee: null,
  mv_fee: null,
  sales_tax: null,
  payment_status_id: null,
  paperwork_received_date: null,
  paperwork_sent_to_dmv: null,
  paperwork_estimated_return: null,
  paperwork_returned_from_dmv: null,
  paperwork_tracking: "",
  rejected: false,
  rejection_reason: "",
  rejection_sent_to_dmv_date: null,
  rejection_estimated_return: null,
  rejection_returned_from_dmv: null,
  rejection_tracking: "",
  created_by_user: { first_name: "", last_name: "" },
  last_edited_by_user: { first_name: "", last_name: "" },
  checks: [
    // {
    //   id: NaN,
    //   check_number: null,
    //   amount: null,
    //   dealership_id: null,
    //   check_status_id: null,
    //   check_date: null,
    // },
  ] as Check[],
};

export const dealershipColumns = [
  { field: "dealership_name", headerName: "Dealership", flex: 1 },
  { field: "contact_name", headerName: "Contact", flex: 1 },
  { field: "phone_1", headerName: "Phone number", flex: 1 },
  { field: "email_address", headerName: "Email Address", flex: 1 },
  {
    field: "address",
    headerName: "Address",
    flex: 2,
    valueGetter: (params) => {
      return (
        (params.row.address || "") +
        " " +
        (params.row.address_2 || "") +
        " " +
        (params.row.city || "") +
        " " +
        (params.row.state || "") +
        " " +
        (params.row.zipcode || "")
      );
    },
  },
];
export const PaymentStatusType = {
  NOT_STARTED: 1,
  INVOICED: 2,
  PARTIALLY_PAID: 3,
  PAID: 4,
  UNKNOWN: 5,
};
export const JobStatusType = {
  MISSING_PAPERWORK: 1,
  IN_PROCESS: 2,
  COMPLETED: 3,
  REJECTED: 4,
  OLD_SYSTEM: 5,
};

export const defaultJobStats = {
  all: 0,
  inProcess: 0,
  completed: 0,
  rejectedMissing: 0,
  paid: 0,
  unpaid: 0,
};
