import React, { useEffect, useState } from "react";
import { Grid, Button, Card, CardContent, FormControl } from "@mui/material";
import { useDispatch } from "../../state";
import { TextField } from "formik-mui";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { adminActions } from "../../state";
import { useParams } from "react-router";
import { RootStyled, GridStyled } from "./UserPage.styles";

const validationSchema = yup.object({
  first_name: yup.string().required("First Name is required"),
  last_name: yup.string().required("Last Name is required"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
});

const CreateUsersPage = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [editMode, setEditMode] = useState(false);
  const [initialValues, setInitialValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    roles: [],
  });

  useEffect(() => {
    (async () => {
      if (params.id) {
        const user: any = await dispatch(adminActions.getUser(params.id));
        if (user) {
          setEditMode(true);
          setInitialValues(user);
        }
      }
    })();
  }, [dispatch, params.id]);

  const handleSubmit = (values, { setSubmitting }) => {
    if (editMode) {
      dispatch(adminActions.updateUser(params.id, values));
    } else {
      dispatch(adminActions.createUser(values));
    }
    setSubmitting(false);
  };

  // const handleSelectChange = (event, setFieldValue) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   console.log({ value });

  //   setFieldValue("roles", value);
  // };
  return (
    <RootStyled>
      <Card>
        <CardContent sx={{ pt: 6 }}>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              isSubmitting,
              errors,
              touched,
              values,
              handleChange,
              setFieldValue,
            }) => (
              <Form>
                <Grid container>
                  <GridStyled item>
                    <Field
                      component={TextField}
                      fullWidth
                      variant="outlined"
                      id="first_name"
                      name="first_name"
                      label="First Name"
                      error={touched.first_name && Boolean(errors.first_name)}
                      helperText={touched.first_name && errors.first_name}
                    />
                  </GridStyled>
                  <GridStyled item>
                    <Field
                      component={TextField}
                      fullWidth
                      name="last_name"
                      label="Last Name"
                      required
                      error={touched.last_name && Boolean(errors.last_name)}
                      helperText={touched.last_name && errors.last_name}
                    />
                  </GridStyled>
                </Grid>

                <Grid container>
                  <GridStyled item>
                    <Field
                      component={TextField}
                      fullWidth
                      type="email"
                      id="email"
                      name="email"
                      label="Email address"
                      disabled={!!editMode}
                      error={touched.email && Boolean(errors.email)}
                    />
                  </GridStyled>
                  <GridStyled item>
                    <FormControl sx={{ width: "100%" }}>
                      {/*<InputLabel>Roles</InputLabel>*/}
                      {/*<Select*/}
                      {/*  // labelId="demo-multiple-chip-label"*/}
                      {/*  id="demo-multiple-chip"*/}
                      {/*  multiple*/}
                      {/*  value={values.roles}*/}
                      {/*  onChange={(e) => handleSelectChange(e, setFieldValue)}*/}
                      {/*  input={*/}
                      {/*    <OutlinedInput*/}
                      {/*      id="select-multiple-chip"*/}
                      {/*      label="Chip"*/}
                      {/*    />*/}
                      {/*  }*/}
                      {/*  renderValue={(selected) => (*/}
                      {/*    <Box*/}
                      {/*      sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}*/}
                      {/*    >*/}
                      {/*      {console.log({ selected })}*/}
                      {/*      {selected.map((value) => (*/}
                      {/*        <Chip*/}
                      {/*          key={value}*/}
                      {/*          label={*/}
                      {/*            availableRoles.find((r) => r.id === value)*/}
                      {/*              ?.name*/}
                      {/*          }*/}
                      {/*        />*/}
                      {/*      ))}*/}
                      {/*    </Box>*/}
                      {/*  )}*/}
                      {/*  MenuProps={MenuProps}*/}
                      {/*>*/}
                      {/*  {availableRoles.map((role, i) => (*/}
                      {/*    <MenuItem key={`role-${role.id}`} value={role.id}>*/}
                      {/*      {role.name}*/}
                      {/*    </MenuItem>*/}
                      {/*  ))}*/}
                      {/*</Select>*/}
                    </FormControl>
                  </GridStyled>
                </Grid>

                <Grid container justifyContent="flex-end">
                  <GridStyled
                    item
                    sx={{ display: "flex", justifyContent: "end" }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                  </GridStyled>
                </Grid>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </RootStyled>
  );
};

export default CreateUsersPage;
