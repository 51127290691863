import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";
import { ErrorMessage } from "formik";

export const RootStyled = styled(`div`)(({ theme }) => ({
  height: "calc(100vh - 72px)",
  width: "1030px",
  border: "0px",
}));

export const GridContainer = styled(Grid)(({ theme }) => ({
  marginBottom: 40,
  paddingLeft: "2em",
  alignItems: "center",
  gap: "24px",
}));

export const ErrorMessageStyled = styled(ErrorMessage)(({ theme }) => ({
  color: "red",
}));

export const GridStyled = styled(Grid)(({ theme }) => ({
  width: 452,
  padding: "0 2em 2em",
  "& .MuiSelect-select": {
    padding: 13,
    minHeight: 58,
    boxSizing: "border-box",
  },
  "& .MuiFormControl-root": {
    width: "328px",
  },
}));
