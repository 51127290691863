import React from "react";
import {
  //
  Button,
  Box,
  Link,
  TextField,
  Typography,
} from "@mui/material";
// Assets
import logo from "../../assets/logo/alpine-logo.png";
// Local
import { Form, RouterLink, useFormik, yup } from "../../lib";
import { authActions, useAction } from "../../state";
import { useStyles } from "./Auth.styles";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

export const LoginAfterResetPage = React.memo(
  /**
   *
   */
  function LoginPage() {
    const classes = useStyles();
    const login = useAction(authActions.login);

    const [error, setError] = React.useState("");
    const frm = useFormik({
      initialValues: {
        email: "",
        password: "",
      },
      validationSchema,
      async onSubmit(values) {
        const success = await login(values);
        if (!success) {
          setError("Invalid username or password. Please try again.");
        }
      },
    });

    return (
      <Box className={classes.authCard}>
        <div className={classes.logo}>
          <img src={logo} alt="" height={72} />
        </div>
        <Typography variant="h1" className={classes.titleText}>
          Your password was reset!
        </Typography>
        <Typography
          variant="subtitle1"
          className={classes.subtitleText}
          sx={{ mt: 1, mb: 2 }}
        >
          {error ? (
            <span className={classes.errorText}>{error}</span>
          ) : (
            "Sign in again and you're good to go"
          )}
        </Typography>
        <Form form={frm} className={classes.form}>
          <TextField
            autoFocus
            fullWidth
            id="email"
            name="email"
            label="Email"
            value={frm.values.email}
            onChange={frm.handleChange}
            error={frm.touched.email && Boolean(frm.errors.email)}
            helperText={frm.touched.email && frm.errors.email}
            className={classes.textFieldInput}
          />
          <TextField
            fullWidth
            id="password"
            name="password"
            label="Password"
            type="password"
            value={frm.values.password}
            onChange={frm.handleChange}
            error={frm.touched.password && Boolean(frm.errors.password)}
            helperText={frm.touched.password && frm.errors.password}
            className={classes.textFieldInput}
          />
          <div className={classes.buttonBox}>
            <Button
              variant="contained"
              type="submit"
              disabled={frm.isSubmitting}
            >
              Sign In
            </Button>
            <div className={classes.forgotPassBox}>
              <Link
                component={RouterLink}
                to={`/auth/forgot-password?email=${encodeURIComponent(
                  frm.values.email,
                )}`}
              >
                Forgot Password?
              </Link>
            </div>
          </div>
        </Form>
      </Box>
    );
  },
);
