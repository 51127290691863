import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

export const SectionHeaderText = styled(Typography)(({ theme }) => ({
  height: 21,
  minWidth: 90,
  color: "rgba(0,0,0,0.87)",
  fontFamily: "Roboto",
  fontSize: "18px",
  letterSpacing: "0.13px",
  lineHeight: "21px",
  marginBottom: "24px",
  marginTop: "0px",
}));
