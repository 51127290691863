import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { arrayToObjById } from "../utils";
import {
  User,
  Status,
  CheckStatus,
  PaymentStatus,
  Make,
  Check,
  Credit,
  Dealership,
  Job,
  Role,
  Service,
  UserRole,
  JobStats,
  defaultJobStats,
} from "../types";

export interface DataState {
  statuses: Record<number, Status>;
  checkStatuses: Record<number, CheckStatus>;
  paymentStatuses: Record<number, PaymentStatus>;
  makes: any[];
  checks: Record<number, Check>;
  credits: Record<number, Credit>;
  users: Record<number, User>;
  dealerships: Record<number, Dealership>;
  activeDealership: any;
  dealershipDetails: any;
  jobs: any[];
  activeJob: Job | {};
  jobStats: JobStats;
  unpaidJobs: Record<number, Job>;
  roles: Record<number, Role>;
  services: Record<number, Service>;
  userRoles: Record<number, UserRole>;
  editCheckNumSuccess: Boolean;
}

const initialState: DataState = {
  statuses: {},
  checkStatuses: {},
  paymentStatuses: {},
  activeJob: {},
  jobStats: defaultJobStats,
  makes: [] as any[],
  checks: {},
  editCheckNumSuccess: false,
  credits: {},
  users: {},
  dealerships: {},
  activeDealership: {},
  dealershipDetails: {},
  jobs: [],
  unpaidJobs: {},
  roles: {},
  services: {},
  userRoles: {},
};

export const data = {
  ...createSlice({
    name: "data",
    initialState,
    reducers: {
      setStatuses(state, { payload }: PayloadAction<[Record<number, Status>]>) {
        state.statuses = arrayToObjById(payload, "id");
      },
      editCheckNumSuccess(state, { payload }: PayloadAction<boolean>) {
        state.editCheckNumSuccess = payload;
      },

      setStatus(state, { payload }: PayloadAction<Status>) {
        state.statuses[payload.id] = payload;
      },
      setCheckStatuses(
        state,
        { payload }: PayloadAction<[Record<number, CheckStatus>]>,
      ) {
        state.checkStatuses = arrayToObjById(payload, "id");
      },
      setCheckStatus(state, { payload }: PayloadAction<CheckStatus>) {
        state.checkStatuses[payload.id] = payload;
      },
      setPaymentStatuses(
        state,
        { payload }: PayloadAction<[Record<number, PaymentStatus>]>,
      ) {
        state.paymentStatuses = arrayToObjById(payload, "id");
      },
      setPaymentStatusesFiltered(
        state,
        { payload }: PayloadAction<[Record<number, PaymentStatus>]>,
      ) {
        state.paymentStatuses = arrayToObjById(
          payload.filter((option: any) => {
            return option.id !== 3 && option.id !== 4;
          }),
          "id",
        );
      },
      setPaymentStatus(state, { payload }: PayloadAction<PaymentStatus>) {
        state.paymentStatuses[payload.id] = payload;
      },
      setMakes(state, { payload }: PayloadAction<[Record<number, Make>]>) {
        state.makes = payload;
      },
      setChecks(state, { payload }: PayloadAction<[Record<number, Check>]>) {
        state.checks = arrayToObjById(payload, "id");
      },
      setCheck(state, { payload }: PayloadAction<Check>) {
        state.checks[payload.id] = payload;
      },
      setCredits(state, { payload }: PayloadAction<[Record<number, Credit>]>) {
        state.credits = arrayToObjById(payload, "id");
      },
      setCredit(state, { payload }: PayloadAction<Credit>) {
        state.credits[payload.id] = payload;
      },
      setDealerships(
        state,
        { payload }: PayloadAction<[Record<number, Dealership>]>,
      ) {
        state.dealerships = arrayToObjById(payload, "id");
      },

      setActiveDealership(state, { payload }: PayloadAction<Dealership>) {
        state.activeDealership = payload;
      },
      setDealershipDetails(state, { payload }: PayloadAction<Dealership>) {
        state.dealershipDetails = payload;
      },
      setJobs(state, { payload }: PayloadAction<[Record<number, Job>]>) {
        state.jobs = payload;
      },
      setJobStats(state, { payload }: PayloadAction<JobStats>) {
        state.jobStats = payload;
      },
      setActiveJob(state, { payload }: PayloadAction<Job>) {
        state.activeJob = payload;
      },
      setRoles(state, { payload }: PayloadAction<[Record<number, Role>]>) {
        state.roles = arrayToObjById(payload, "id");
      },
      setRole(state, { payload }: PayloadAction<Role>) {
        state.roles[payload.id] = payload;
      },
      setServices(
        state,
        { payload }: PayloadAction<[Record<number, Service>]>,
      ) {
        state.services = arrayToObjById(payload, "id");
      },
      setService(state, { payload }: PayloadAction<Service>) {
        state.services[payload.id] = payload;
      },
      setUserRoles(
        state,
        { payload }: PayloadAction<[Record<number, UserRole>]>,
      ) {
        state.userRoles = arrayToObjById(payload, "id");
      },
      setUserRole(state, { payload }: PayloadAction<UserRole>) {
        state.userRoles[payload.id] = payload;
      },
      setUsers(state, { payload }: PayloadAction<[Record<number, User>]>) {
        state.users = arrayToObjById(payload, "id");
      },
      setUser(state, { payload }: PayloadAction<User>) {
        state.users[payload.id] = payload;
      },
    },
  }),
  persist: false,
};
