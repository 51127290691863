export { bindActionCreators } from "redux";
export { connect, useDispatch, useSelector, useStore } from "react-redux";

export function arrayToObjById(arr: any[], idKey: string | Array<string>) {
  if (typeof idKey === "string") {
    return arr.reduce((acc, item) => {
      acc[item[idKey]] = item;
      return acc;
    }, {});
  } else if (Array.isArray(idKey)) {
    if (idKey.length === 0) {
      throw new Error("idKey must be a non-empty array");
    }
    return arr.reduce((acc, item) => {
      let id = item[idKey[0]];

      for (let i = 1; i < idKey.length; i++) {
        id = id + "_" + item[idKey[i]];
        console.log("id", id);
      }
      acc[id] = item;
      return acc;
    }, {});
  }
}
export function objToArray(obj: Record<any, any>) {
  const arr: any[] = [];
  Object.keys(obj).forEach((k) => {
    arr.push(obj[k]);
  });
  return arr;
}
