import React, { useEffect, useState } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AdminPages from "../index";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import { RootStyled } from "./DealershipPage.styles";
import { Create } from "@mui/icons-material";
import { dataActions } from "../../../state";
import { dataSelectors } from "../../../state";
import { ConfirmDeleteDialog } from "../../../components/dialogs/ConfirmDeleteDialog";
import { StatusLabel } from "../../../components/labels/StatusLabel";
import { PaymentStatusLabel } from "../../../components/labels/PaymentStatusLabel";
import currency from "currency.js";
import { useParams } from "react-router";
import { Form, useFormik, yup } from "../../../lib";
import { Grid } from "@mui/material/";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";
import HomeIcon from "@mui/icons-material/Home";
import { DatePicker } from "@mui/x-date-pickers-pro";

export const DealershipPage = () => {
  const dispatch = useDispatch();
  const params: any = useParams();
  const dealership = useSelector(dataSelectors.dealershipDetails);
  const [selectionModel, setSelectionModel] = useState<any>([]);

  useEffect(() => {
    if (params.id) {
      dispatch(dataActions.getDealershipDetails(params.id));
    }
  }, [params.id, dispatch]);

  const partiallyPaidJobs =
    dealership?.jobs?.filter(
      (job) => job.payment_status.name === "PARTIALLY PAID",
    ) || [];

  useEffect(() => {
    setSelectionModel(
      dealership?.jobs?.map((unpaidJob) => {
        return unpaidJob.id;
      }),
    );
  }, [dealership?.jobs]);

  const columns = [
    { field: "state", headerName: "State", width: 150 },
    { field: "invoice_number", headerName: "Invoice Number", width: 150 },
    {
      field: "check_number",
      headerName: "Check Number",
      width: 150,
      valueGetter: (row) => {
        return (
          row.row.checks.map((check) => check.check_number).join(" ") || ""
        );
      },
    },
    {
      field: "status_id",
      headerName: "Job Status",
      width: 200,
      renderCell: (params) => <StatusLabel status={params.row.status.name} />,
    },
    {
      field: "payment_status_id",
      headerName: "Payment Status",
      width: 150,
      renderCell: (params) => (
        <PaymentStatusLabel status={params.row.payment_status.name} />
      ),
    },
    { field: "customer_name", headerName: "Customer Name", width: 150 },
    {
      field: "paperwork_received_date",
      headerName: "Received Date",
      width: 150,
    },

    {
      field: "service_id",
      headerName: "Service",
      width: 150,
      valueGetter: (params) => params.row.service.name,
    },
    {
      field: "total",
      headerName: "Total",
      width: 150,
      valueGetter: (params) => {
        return currency(params.row.mv_fee)
          .add(params.row?.alpine_fee)
          .add(params.row.sales_tax)
          .format();
      },
    },
    {
      field: "paid",
      headerName: "Paid",
      width: 150,

      valueGetter: (params) => {
        return currency(
          params.row.check_jobsq?.reduce((acc, check) => {
            return acc.add(check.amount);
          }, currency(0)),
        ).format();
      },
    },
    {
      field: "created_by_user_id",
      headerName: "Created By",
      width: 150,
      valueGetter: (params) =>
        params.row.created_by_user.first_name +
        " " +
        params.row.created_by_user.last_name,
    },
    {
      field: "last_edited_by_user_id",
      headerName: "Last Edited By",
      width: 150,
      valueGetter: (params) =>
        params.row.last_edited_by_user.first_name +
        " " +
        params.row.last_edited_by_user.last_name,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <>
          <Link
            to={AdminPages.editJob.path.replace(":id", params.id.toString())}
          >
            <IconButton color="primary" size="small">
              <Create />
            </IconButton>
          </Link>
          <ConfirmDeleteDialog
            handleConfirm={() => dispatch(dataActions.deleteJob(params.id))}
          />
        </>
      ),
    },
  ];

  const validationSchema = yup.object({});
  const creditValidationSchema: any = yup.object({
    amount: yup.number().required(),
  });
  let today = new Date();
  const form = useFormik({
    initialValues: {
      check_date:
        "" +
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate(),
      check_number: "",
      amount: "",
    },
    validationSchema,
    enableReinitialize: true,
    async onSubmit(values) {
      await dispatch(
        dataActions.applyCheck(
          {
            ...values,
            dealership_id: parseInt(params.id as string),
            jobs: selectionModel,
            amount: currency(values.amount).value,
          },
          "updateDealership",
        ),
      );
      await form.setFieldValue("check_number", "");
    },
  });
  const creditForm = useFormik({
    initialValues: {
      amount: 0,
    },
    validationSchema: creditValidationSchema,
    enableReinitialize: true,
    async onSubmit(values) {
      dispatch(
        dataActions.applyCredit({
          amount: parseFloat(values.amount as any),
          dealership_id: parseInt(params.id as string),
        }),
      );
    },
  });

  useEffect(() => {
    form.setFieldValue(
      "amount",

      currency(
        dealership?.jobs?.reduce((acc, unpaidJob) => {
          if (selectionModel.includes(unpaidJob.id)) {
            return currency(acc)
              .add(unpaidJob.mv_fee)
              .add(unpaidJob?.alpine_fee)
              .add(unpaidJob.sales_tax);
          } else {
            return acc;
          }
        }, currency(0)).value,
      )
        .subtract(
          partiallyPaidJobs.reduce((acc, partiallyPaidJob) => {
            if (selectionModel.includes(partiallyPaidJob.id)) {
              return currency(acc).add(
                partiallyPaidJob.check_jobsq?.reduce((acc, checkJob) => {
                  return currency(acc).add(checkJob.amount);
                }, currency(0)),
              );
            } else {
              return acc;
            }
          }, currency(0)),
        )
        .format() || 0,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionModel]);
  return (
    <RootStyled>
      <Grid container alignItems={"flex-start"}>
        <Link to={"/admin/dealerships"}>
          <IconButton sx={{ paddingLeft: 0 }}>
            <ArrowBackIcon />
          </IconButton>
        </Link>
        <Box sx={{ mb: 6 }}>
          <Grid container alignItems={"center"}>
            <Typography variant="h1">
              {params.id ? dealership.dealership_name : "Dealership"}
            </Typography>
            <Link
              to={AdminPages.editDealership.path.replace(
                ":id",
                params.id.toString(), // params.id.toString(),
              )}
            >
              <IconButton size="small">
                <Create />
              </IconButton>
            </Link>
          </Grid>
          <Grid container sx={{ opacity: 0.6 }} alignItems={"center"}>
            <AccountCircleIcon sx={{ mr: 1 }} />
            <Typography variant="body1">
              {params.id ? dealership?.contact_name : "Contact Name"}
            </Typography>
          </Grid>
          {params.id && dealership?.phone_1 && (
            <Grid container sx={{ opacity: 0.6 }} alignItems={"center"}>
              <PhoneIcon sx={{ mr: 1 }} />
              <Typography variant="body1">
                {params.id ? dealership?.phone_1 : "Phone"}
              </Typography>
            </Grid>
          )}
          <Grid container sx={{ opacity: 0.6 }} alignItems={"center"}>
            <MailIcon sx={{ mr: 1 }} />
            <Typography variant="body1">
              {params.id ? dealership?.email_address : "Email"}
            </Typography>
          </Grid>

          <Grid container sx={{ opacity: 0.6 }} alignItems="center">
            <HomeIcon sx={{ mr: 1 }} />
            <Typography variant="body1">
              {params.id
                ? `${dealership?.address || ""} ${
                    dealership?.address_2 || ""
                  } ${dealership?.city || ""} ${dealership?.state || ""} ${
                    dealership?.zipcode || ""
                  } `
                : "Address"}
            </Typography>
          </Grid>
        </Box>
      </Grid>
      <Typography variant="h4" sx={{ mb: 1 }}>
        Payments
      </Typography>
      <Typography sx={{ mb: 2, color: "text.secondary" }} variant="body1">
        Current balance:
        {params.id
          ? currency(dealership?.balance as number | string | currency).format()
          : "Unknown"}
      </Typography>
      <Form form={form}>
        <Grid container spacing={3} alignItems={"center"} sx={{ mb: 3 }}>
          <Grid item md={3}>
            <TextField
              label="Amount"
              name="amount"
              fullWidth
              value={form.values.amount}
              onChange={form.handleChange}
              variant="outlined"
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <DatePicker
              label="Check Date"
              value={form.values.check_date}
              onChange={(newValue) => {
                form.setFieldValue("check_date", newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} required fullWidth />
              )}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <TextField
              label="Check Number"
              name="check_number"
              fullWidth
              variant="outlined"
              value={form.values.check_number}
              onChange={form.handleChange}
              required={true}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <Button
              variant="contained"
              color="primary"
              disabled={!selectionModel?.length}
              onClick={() => {
                form.handleSubmit();
              }}
            >
              Apply Check
            </Button>
          </Grid>
        </Grid>
      </Form>
      {dealership?.balance < 0 && (
        <Form form={creditForm}>
          <Grid container spacing={3} alignItems={"center"} sx={{ mb: 3 }}>
            <Grid item md={4}>
              <TextField
                label="Amount"
                name="amount"
                fullWidth
                value={creditForm.values.amount}
                onChange={creditForm.handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  creditForm.handleSubmit();
                }}
              >
                Apply Credit
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
      <Grid
        container
        justifyContent={"flex-start"}
        alignItems={"center"}
        sx={{ mb: 2 }}
      >
        <Typography variant="h4">For unpaid Jobs:</Typography>
      </Grid>
      <DataGridPro
        checkboxSelection={true}
        rows={dealership?.jobs || []}
        columns={columns}
        selectionModel={selectionModel}
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel);
          form.setFieldValue(
            "amount",
            currency(
              dealership?.jobs?.reduce((acc, unpaidJob) => {
                if (selectionModel.includes(unpaidJob.id)) {
                  return currency(acc)
                    .add(unpaidJob.mv_fee)
                    .add(unpaidJob?.alpine_fee)
                    .add(unpaidJob.sales_tax);
                } else {
                  return acc;
                }
              }, currency(0)).value,
            )
              .subtract(
                partiallyPaidJobs.reduce((acc, partiallyPaidJob) => {
                  if (selectionModel.includes(partiallyPaidJob.id)) {
                    return currency(acc).add(
                      partiallyPaidJob.check_jobsq?.reduce((acc, checkJob) => {
                        return currency(acc).add(checkJob.amount);
                      }, currency(0)),
                    );
                  } else {
                    return acc;
                  }
                }, currency(0)),
              )
              .format() || 0,
          );
        }}
      />
    </RootStyled>
  );
};
