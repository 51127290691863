import React from "react";
import NumberFormat from "react-number-format";

export const NumberFormatter = React.forwardRef((props: any, ref) => {
  const { onChange, name, ...rest } = props;

  return (
    <NumberFormat
      getInputRef={ref}
      name={name}
      {...rest}
      onValueChange={(values) => {
        onChange({
          target: {
            name: name,
            value: Number(values.value),
          },
        });
      }}
    />
  );
});

export const DollarFormatter = React.forwardRef((props: any, ref) => {
  const { onChange, name, placeholder, ...rest } = props;
  return (
    <NumberFormat
      getInputRef={ref}
      placeholder="0.00"
      allowleadingzeroes="false"
      thousandSeparator={true}
      decimalScale={2}
      allowNegative={false}
      name={name}
      {...rest}
      onValueChange={(values) => {
        onChange({
          target: {
            name: name,
            value: Number(values.floatValue),
          },
        });
      }}
    />
  );
});
