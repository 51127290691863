import React, { useEffect } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useDispatch, useSelector } from "react-redux";
import { adminActions, adminSelectors } from "../../state";
import { Link } from "react-router-dom";
import AdminPages from ".";
import { IconButton } from "@mui/material";
import { RootStyled } from "./UsersPage.styles";
import { Create } from "@mui/icons-material";
import { format } from "date-fns";

const columns = [
  {
    field: "id",
    headerName: "Actions",
    width: 150,
    renderCell: (params) => (
      <>
        <Link
          to={AdminPages.editUsers.path.replace(":id", params.id.toString())}
        >
          <IconButton color="primary" size="small">
            <Create />
          </IconButton>
        </Link>
      </>
    ),
  },
  { field: "first_name", headerName: "First Name", width: 150 },
  { field: "last_name", headerName: "Last Name", width: 150 },
  { field: "email", headerName: "Email", width: 150 },
  { field: "login_attempts", headerName: "Login Attempts", width: 150 },
  {
    field: "last_login",
    headerName: "Last Login",
    width: 190,
    valueFormatter: (v) =>
      //currently does not work but keep an eye open toi make sure this is the correct date value because the LocalizationProvider in index.js may change the current value
      v && typeof v.value === "string" ? format(new Date(v.value), "Pp") : "",
  },
  {
    field: "last_failed_login",
    headerName: "Last Failed Login",
    width: 190,
    valueFormatter: (v) =>
      v && typeof v.value === "string" ? format(new Date(v.value), "Pp") : "",
  },
];

export const UsersPage = (props) => {
  const dispatch = useDispatch();
  const users = useSelector(adminSelectors.users);

  useEffect(() => {
    dispatch(adminActions.getUsers());
  }, [dispatch]);

  return (
    <RootStyled>
      <DataGridPro rows={users.rows} columns={columns} />
    </RootStyled>
  );
};
