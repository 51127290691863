import { AppState } from "../types";

export const listsSelectors = {
  customerNameList(state: AppState) {
    return (
      state.lists.customerNameList
        ?.map((p) => p.customer_name)
        ?.filter((n) => n && !!n.length) ?? []
    );
  },
  checkNumberList(state: AppState) {
    return (
      state.lists.checkNumberList
        ?.map((p) => p.check_number)
        ?.filter((n) => n && !!n.length) ?? []
    );
  },
  dealershipsList(state: AppState) {
    return state.lists.dealershipsList ?? [];
  },
};
